export const SET_FORMAT = 'SET_FORMAT';


export const setFormatByName = (defaultPreConfigName)  => {
  const action = {
    type: SET_FORMAT,
    payload: {
      defaultPreConfigName,
    },
  };

  return (dispatch) => dispatch(action);
};
