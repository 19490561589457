import {actionTypeConstant} from '../factories/constant';

export const setToast = (payload) => {
  const action = {
    type: actionTypeConstant.SET_TOAST,
    payload,
  };

  return (dispatch) => dispatch(action);
};
