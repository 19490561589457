import React from "react";

const buttonLoader = () => {
    return (
        <div className="button-progress">
            <img height="50px" src="images/loader.gif"/>
        </div>
    );
};

export default buttonLoader;