import {actionTypeConstant, notificationDialogType} from '../factories/constant';

export const showCustomDialog = (dialogId, data, type = notificationDialogType.NOTICE) => {
  const action = {
    type: actionTypeConstant.SHOW_CUSTOM_DIALOG,
    payload: {
      dialogId,
      type,
      data,
    },
  };

  return (dispatch) => dispatch(action);
};

export const cleanCustomDialog = () => {
  const action = {
    type: actionTypeConstant.CLEAR_CUSTOM_DIALOG,
  };

  return (dispatch) => dispatch(action);
};
