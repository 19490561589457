import { actionTypeConstant } from '../factories/constant';
import { addTargetToList } from '../utils/onboarding.util';

const defaultTargetProps = {
  dialogPlacement: 'right middle',
};

const emptyTarget = {
  target: '',
  targetProps: defaultTargetProps,
  tourList: [],
};

const initialState = emptyTarget;

export default function(state = initialState, action) {
  switch(action.type) {
    case actionTypeConstant.SET_ONBOARDING_TARGET: {
      return {
        ...state,
        target: action.payload.target,
        targetProps: action.payload.targetProps ? action.payload.targetProps : defaultTargetProps,
      }
    }
    case actionTypeConstant.SET_ONBOARDING_LIST: {
      const newList = addTargetToList(action.payload, state.tourList);
      return {
        ...state,
        tourList: newList,
      }
    }
    default: {
      return state;
    }
  }
}
