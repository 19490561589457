import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';

import { LocalStorageRepository } from '../repositories/local-storage.repository';
import { setUserVerify, setSocket } from '../actions';

const SocketHoc = ({ children, user, setUserVerify, setSocket }) => {
  const notificationCenterUri = process.env.REACT_APP_NOTIFICATION_CENTER_SERVICE_URL;

  const configSocket = () => {
    if (user.socket.hasConnection) {
      console.log('Socket Connect Event');
      const isLogged = user && user.isLoggedIn;
      const socket = user && user.socket && user.socket.socket;
      const token = LocalStorageRepository.getAccessToken();
      if ((isLogged || token) && !socket) {
        console.log('User socket ready');
        const currentSocket = io(notificationCenterUri, { auth: { token: `${token}` }});
        setSocket(currentSocket);
      }
    } else {
      if (user.socket.socket) {
        console.log('Socket Disconnect Event');
        user.socket.socket.close();
      }
    }
  };

  useEffect(() => {
    if (user.socket.socket) {
      const currentSocket = user.socket.socket;
      console.log('Current socket', currentSocket);

      currentSocket.on('connect', () => {
        console.log(currentSocket.id);
      });

      currentSocket.on('joined', (data) => {
        console.log('joined: ', data);
      });

      currentSocket.on('notification', (notification) => {
        console.log('notifications: ', notification);
        if (notification.type === 'verified') {
          const isVerified = notification.data && notification.data.event ? notification.data.event === 'verified.success' : false;
          if (isVerified) {
            setUserVerify();
            LocalStorageRepository.setAccessToken(notification.data.token);
            LocalStorageRepository.setRefreshAccessToken(notification.data.refreshToken);
            LocalStorageRepository.setSessionToken(notification.data.sessionToken);
          }
        }
      });

      currentSocket.on('leave', (data) => {
        console.log('leave:', data);
      });

      currentSocket.on('disconnect', () => {
        console.log('disconnected socket');
        setSocket(null);
      });

      currentSocket.on('error', (data) => {
        console.log('error: ', data);
        currentSocket.close();
      });
    }
  }, [user.socket.socket]);

  useEffect(() => {
    configSocket();
  }, [user.socket.hasConnection]);

  useEffect(() => {
    return () => {
      if (user.socket.socket) {
        user.socket.socket.close();
      }
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setUserVerify,
  setSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketHoc);
