import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/index';

const loggerMiddleware = createLogger();
const middlewares = [thunkMiddleware];
const isDebug = localStorage.getItem('debug');
if (process.env.NODE_ENV !== 'production' || isDebug) middlewares.push(loggerMiddleware);

window.debug = () => {
  localStorage.setItem('debug', true);
  console.log('REDUX DEBUG ENABLED');
};

export default  createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);