import {
  httpService,
  httpErrorHandle,
} from '../factories/httpService';

import { httpConstants } from '../factories/constant';

import { LocalStorageRepository } from '../repositories/local-storage.repository';

const serviceURL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

async function loginAttempt(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_LOGIN_ATTEMPT}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function login(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_LOGIN}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function logout(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_LOGOUT}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function passwordLostEmail(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_PASSWORD_LOST_EMAIL}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function passwordReset(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_PASSWORD_RESET}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function userSignUp(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_SIGN_UP}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function userVerify(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_VERIFY}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function updatePersonalData(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_UPDATE_PERSONAL_DATA}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function addedSign(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_ADD_SIGN}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function changedSign(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_CHANGE_SIGN}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function updatePassword(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_UPDATE_PASSWORD}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

async function userAddSubUser(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.USER_ADDED_SUB_USER}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}`};

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return response.responseData;
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        requestData,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    });
}

export default {
  loginAttempt,
  login,
  logout,
  passwordLostEmail,
  passwordReset,
  userSignUp,
  userVerify,
  updatePersonalData,
  addedSign,
  changedSign,
  updatePassword,
  userAddSubUser,
};
