import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BillingService } from '../../services/billing.service';
import { stripeTransactionType } from '../../factories/constant';

const CardMethodButton = ({typeIntent, methodId, buttonTextId, onSuccess, onError, onProcessing}) => {

  const handleUpdateMethod = async() => {
    try {
      onProcessing();
      const responseMethod = await BillingService.setMethodDefault(methodId);
      if (!responseMethod) {
        throw new Error('Method Error');
      }
      if (typeIntent) {
        let responseMembership = null;
        switch (typeIntent) {
          case stripeTransactionType.CONFIRM_SETUP_PROMO:
            responseMembership = await BillingService.redeemSubscriptionPromo();
            break;
          case stripeTransactionType.CONFIRM_SETUP:
            responseMembership = await BillingService.startSubscriptionMembership();
            break;
          default:
            break;
        }
        if (responseMembership) {
          onSuccess();
        } else {
          throw new Error('Suscription Error');
        }
      } else {
        onSuccess();
      }
    } catch (e) {
      console.log("UpdatePaymentMethod error => ", e);
      onError();
    }
  };

  return (
    <>
      {methodId && (
        <button onClick={handleUpdateMethod} className="wt-btn wt-btn--circle wt-btn--theme btn-confirm-payment">
          <FormattedMessage id={buttonTextId} />
        </button>
      )}
    </>
  );
};


CardMethodButton.defaultProps = {
  typeIntent: '',
  methodId: '',
  buttonTextId: 'payment.card.proceed',
  onSuccess: () => {},
  onError: () => {},
  onProcessing: () => {},
};

CardMethodButton.propTypes = {
  typeIntent: PropTypes.string,
  methodId: PropTypes.string.isRequired,
  buttonTextId: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onProcessing: PropTypes.func,
};

export default CardMethodButton;
