import { actionTypeConstant } from '../factories/constant';
import { sessionManager } from '../factories/sessionManager';

const initialState = {
  preloadTemplate: null,
  userTemplates: [],
  currentTemplateConfig: sessionManager.getDataFromLocalStorage('currentTemplateConfig'),
  varsTemplateDocument: sessionManager.getDataFromLocalStorage('varsTemplateDocument'),
  massiveFiles: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypeConstant.SET_USER_TEMPLATES: {
      return {
        ...state,
        userTemplates: action.payload,
      }
    }
    case actionTypeConstant.SET_DEFAULT_TEMPLATES: {
      return {
        ...state,
        preloadTemplate: action.payload,
      }
    }
    case actionTypeConstant.SET_CURRENT_TEMPLATE_CONFIG: {
      return {
        ...state,
        currentTemplateConfig: action.payload,
      }
    }
    case actionTypeConstant.SET_VARS_TEMPLATE_DOCUMENT: {
      return {
        ...state,
        varsTemplateDocument: action.payload,
      }
    }
    case actionTypeConstant.REMOVE_TEMPLATE: {
      return {
        ...state,
        userTemplates: state.userTemplates.filter(item => item.templateID !== action.payload),
      }
    }
    case actionTypeConstant.SET_TEMPLATE_MASSIVE_FILES: {
      return {
        ...state,
        massiveFiles: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}

export default reducer;
