import {HttpInterceptorService} from './http-interceptor.service';
import {LocalStorageRepository} from '../repositories/local-storage.repository';

const invoiceUrl = process.env.REACT_APP_BILLING_SERVICE_URL;
const isNotHttpCodeSuccess = (payload) => payload.statusCode && payload.statusCode !== 200;

export const fetchInvoice = async () => {
  try {
    const payload = await HttpInterceptorService.run(async () => {
      const config = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
        },
      };

      const response = await fetch(`${invoiceUrl}/invoices/summary-list?limit=1000&sortBy=createdAt&order=desc`, config);
      return await response.json();
    });

    if (isNotHttpCodeSuccess(payload)) {
      throw new Error(`Access code: ${payload.statusCode}`);
    }

    return payload;
  } catch (e) {
    console.warn('[FETCH_INVOICE] error: ', e);
    return null;
  }
};
