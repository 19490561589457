import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import PaymentNotice from './payment-notice.container';
import AddPaymentMethod from '../components/molecules/payments/add-payment-method.component';
import PaymentProcess from '../components/molecules/payments/payment-process.component';
import PaymentMethods from '../components/molecules/payments/payment-methods.component';
import CardMethodButton from '../components/atoms/card-method-button.component';
import { stripeTransactionType, genericConstants, paymentSteps, googleTagsEvents } from '../factories/constant';
import { updateStatusBanner } from '../utils/notifications.utils';
import { cleanBannerTop } from '../actions/banner.action';
import { updateUserSuccess } from '../actions/user';
import firebase from '../factories/firebase';
import User from '../factories/models/userModel';
import { BillingService } from '../services/billing.service';
import { sendGoogleTagEvent } from '../utils/google-tags.utils';

const PaymentDialogContainer = ({
  user,
  typeNotice,
  typeIntent,
  baseMembership,
  onClose,
  cleanBannerTop,
  updateUserSuccess,
}) => {
  const [isCloseable, setIsCloseable] = useState(true);
  const showCaption = typeNotice === genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT || typeNotice === genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER;
  const [stepProcess, setStepProcess] = useState(paymentSteps.PAYMENT_INIT);
  const isPromoIntent = typeIntent === stripeTransactionType.CONFIRM_SETUP_PROMO;
  const isSetupIntent = typeIntent === stripeTransactionType.CONFIRM_SETUP || typeIntent === stripeTransactionType.CONFIRM_SETUP_PROMO;
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(isPromoIntent ? stripeTransactionType.ADD_NEW_CARD : '');
  const [stripeElementIntent, setStripeElementIntent] = useState('');
  const [hasInitializeElements, setHasInitializeElements] = useState(isPromoIntent);

  useEffect(() => {
    setStripeElementIntent(typeIntent);
  }, [typeIntent]);

  const onHandleClose = () => {
    onClose();
  }

  const handleRetryPayment = () => {
    setIsCloseable(true);
    setStepProcess(paymentSteps.PAYMENT_INIT);
  };

  const handleSuccess = () => {
    setIsCloseable(false);
    setStepProcess(paymentSteps.PAYMENT_PROCESSING);
    validateSuscription();
  };

  const handleError = () => {
    setIsCloseable(true);
    setStepProcess(paymentSteps.PAYMENT_ERROR);
  };

  const handleCompletePayment = () => {
    onClose();
  };

  const handleSelectMethod = (method) => {
    if (method === stripeTransactionType.ADD_NEW_CARD) {
      setHasInitializeElements(true);
    }
    setPaymentMethodSelected(method);
  };

  const handleSendMethodPayment = () => {
    if (paymentMethodSelected !== stripeTransactionType.ADD_NEW_CARD) {
      setIsCloseable(false);
      setStepProcess(paymentSteps.PAYMENT_PROCESSING);
    }
  };

  const handleProcessElements = () => {
    setIsCloseable(false);
    setStepProcess(paymentSteps.PAYMENT_PROCESSING);
  };

  const handleActiveSuscriptionNow = async() => {
    handleProcessElements();
    try {
      const responseBilling = await BillingService.startSubscriptionMembership();
      if (responseBilling) {
        handleSuccess();
      } else {
        throw new Error('Response Error');
      }
    } catch (e) {
      console.log("handleActiveSuscriptionNow error => ", e);
      handleError();
    }
  };

  const handleActiveDynamicMembership = async() => {
    handleProcessElements();
    try {
      const responseBilling = await BillingService.enableDynamicMembership();
      if (responseBilling) {
        handleSuccess();
      } else {
        throw new Error('Response Error');
      }
    } catch (e) {
      console.log("handleActiveDynamicMembership error => ", e);
      handleError();
    }
  };

  const validateSuscription = async() => {
    const responseFirebase = await firebase._getUserFromUserID(user.user.userID);
    if (responseFirebase && Object.keys(responseFirebase).length > 1) {
      let userObj = new User(responseFirebase);
      const suscription = await firebase.getGlobalSubscription(userObj.userID);
      if (suscription) {
        userObj.subscription = suscription;
        updateUserSuccess(userObj);
      }
    }
    cleanBannerTop();
    updateStatusBanner(false);
    setStepProcess(paymentSteps.PAYMENT_SUCCESS);
    sendGoogleTagEvent(googleTagsEvents.CREDIT_CARD_ADDED);
  };

  return (
    <div className="payment-dialog">
      {isCloseable && (
        <div className="wt-dialog__close" onClick={onHandleClose}><i className="wt-icon-close"></i></div>
      )}
      <div className={`payment-dialog__container ${stepProcess === paymentSteps.PAYMENT_INIT ? 'payment-dialog__container--visible' : 'payment-dialog__container--hidden'}`}>
        {typeNotice !== genericConstants.NOTIFICATION_OPERATION.ENABLE_SUSCRIPTION_NOW &&
        typeNotice !== genericConstants.NOTIFICATION_OPERATION.OPEN_KEY_ACTIVATION &&
        typeNotice !== genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN &&
        (
          <div className="payment-dialog__container__line"></div>
        )}
        {typeNotice && (
          <div className="payment-dialog__notice">
            <PaymentNotice type={typeNotice} />
            {typeNotice === genericConstants.NOTIFICATION_OPERATION.ENABLE_SUSCRIPTION_NOW && user.user.role === genericConstants.USER_ROLE.USER && (
              <div className="payment-dialog__notice__action">
                <button className="wt-btn wt-btn--theme wt-btn--circle" onClick={handleActiveSuscriptionNow}>
                  <FormattedMessage id="freeplan.payment.suscription.enable.now" />
                </button>
              </div>
            )}
            {typeNotice === genericConstants.NOTIFICATION_OPERATION.OPEN_KEY_ACTIVATION && (
              <>
                <div className="payment-dialog__notice__action wt-dialog__actions">
                  <button className="wt-btn wt-btn--theme wt-btn--circle" onClick={handleActiveDynamicMembership}>
                    <FormattedMessage id="dinamic.membership.accept.button" />
                  </button>
                </div>
                <div className="payment-dialog__notice__action">
                  <a className="link-dark-underline" onClick={()=>window.drift.api.openChat()}>
                    <FormattedMessage id="dinamic.membership.info.link" />
                  </a>
                </div>
              </>
            )}
            {typeNotice !== genericConstants.NOTIFICATION_OPERATION.OPEN_KEY_ACTIVATION && user.user.role !== genericConstants.USER_ROLE.USER && (
              <div className="payment-dialog__notice__action">
                <FormattedMessage id="freeplan.payment.notice.subuser" />
              </div>
            )}
          </div>
        )}
        {stripeElementIntent && user.user.role === genericConstants.USER_ROLE.USER && (
          <div className="payment-dialog__form">
            <PaymentMethods
              currentMethod={paymentMethodSelected}
              onChange={handleSelectMethod}
            />
            {hasInitializeElements && (
              <div className={`${paymentMethodSelected === stripeTransactionType.ADD_NEW_CARD ? 'd-block': 'd-none'}`}>
                <AddPaymentMethod
                  typeIntent={stripeElementIntent}
                  disabled={paymentMethodSelected !== stripeTransactionType.ADD_NEW_CARD}
                  onSuccess={handleSuccess}
                  onProcessing={handleProcessElements}
                  onError={handleError}
                  buttonTextId="payment.card.setup"
                />
              </div>
            )}
            {paymentMethodSelected !== stripeTransactionType.ADD_NEW_CARD && (
              <div className="payment-dialog__method-btn">
                <CardMethodButton
                  typeIntent={stripeElementIntent}
                  methodId={paymentMethodSelected}
                  onProcessing={handleSendMethodPayment}
                  onSuccess={handleSuccess}
                  onError={handleError}
                  buttonTextId="payment.card.setup"
                />
              </div>
            )}
            {showCaption && (
              <div className="payment-dialog__description">
                <FormattedMessage id="payment.suscription.caption" values={{productPrice: baseMembership && baseMembership.amount ? baseMembership.amount: ''}} />
              </div>
            )}
            <div className="payment-dialog__controls">
              <div className="payment-dialog__controls__wrapper">
                <a className="payment-dialog__reject" onClick={onHandleClose}>
                  <FormattedMessage id="payment.reject" />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {stepProcess === paymentSteps.PAYMENT_PROCESSING && (
        <div className="payment-dialog__process">
          <PaymentProcess
            step={stepProcess}
            title={isSetupIntent ? 'payment.process.setup.loader.title' : 'payment.process.loader.title.generic'}
            message="payment.process.loader.message.generic"
          />
        </div>
      )}
      {stepProcess === paymentSteps.PAYMENT_ERROR && (
        <div className="payment-dialog__process">
          <PaymentProcess
            step={stepProcess}
            title={isSetupIntent ? 'payment.process.setup.error.title' : 'payment.process.error.title.generic'}
            subtitle={isSetupIntent ? 'payment.process.setup.error.subtitle' : 'payment.process.error.subtitle.generic'}
          />
          <div className="payment-dialog__process__actions">
            <button className="wt-btn wt-btn--theme wt-btn--circle" onClick={()=>handleRetryPayment()}>
              <FormattedMessage id="payment.retry.link" />
            </button>
          </div>
        </div>
      )}
      {stepProcess === paymentSteps.PAYMENT_SUCCESS && (
        <div className="payment-dialog__process">
          <PaymentProcess
            step={stepProcess}
            typeIntent={stripeElementIntent}
            title={isSetupIntent ? 'payment.process.setup.success.title' : 'payment.process.success.title.generic'}
            message={!isPromoIntent ? 'payment.process.success.message' : ''}
          />
          <div className="payment-dialog__process__actions">
            <button className="wt-btn wt-btn--theme wt-btn--circle" onClick={()=>handleCompletePayment()}>
              <FormattedMessage id="payment.process.continue.button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

PaymentDialogContainer.defaultProps = {
  typeNotice: '',
  typeIntent: '',
  onClose: () => {},
};

PaymentDialogContainer.propTypes = {
  typeNotice: PropTypes.string,
  typeIntent: PropTypes.string,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  baseMembership: state.products && state.products.baseMembership ? state.products.baseMembership : null,
});

const mapDispatchToProps = {
  cleanBannerTop,
  updateUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDialogContainer);
