import AuthService from './auth.service';
import { LocalStorageRepository } from '../repositories/local-storage.repository';

const isUnauthorizedCode = (payload) => payload.statusCode && payload.statusCode === 401;

export class HttpInterceptorService {
  static async run(cb) {
    try {
      const payload = await cb();

      if (isUnauthorizedCode(payload)) {
        const refreshTokenResponse = await AuthService.getRefreshToken(LocalStorageRepository.getRefreshAccessToken());

        if (isUnauthorizedCode(refreshTokenResponse)) {
          throw new Error('We cant use a refresh token');
        }

        LocalStorageRepository.setAccessToken(refreshTokenResponse.token);
        LocalStorageRepository.setRefreshAccessToken(refreshTokenResponse.refreshToken);

        const newPayload = await cb(LocalStorageRepository.getAccessToken());
        if (isUnauthorizedCode(newPayload)) {
          throw new Error('Failed fetch again');
        }
        return newPayload;
      }

      return payload;
    } catch (e) {
      return null;
    }
  }
}
