import {actionTypeConstant} from '../factories/constant';

export const setProducts = (products) => {
  const action = {
    type: actionTypeConstant.SET_PRODUCTS,
    payload: products,
  };

  return (dispatch) => dispatch(action);
};
