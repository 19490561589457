import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { screenPathConstants } from '../factories/constant';
import Loader from '../components/atoms/loader.component';
import { LocalStorageRepository } from '../repositories/local-storage.repository';
import { getAllParams } from '../utils/search-params.util';

const ProtectedRoute = ({ router, children, auth }) => {

  const unprotectedRoutes = [
    screenPathConstants.LOGIN,
    screenPathConstants.REGISTER,
    screenPathConstants.HOME,
    screenPathConstants.VALIDATION,
    screenPathConstants.VALIDATION_URL,
    screenPathConstants.TOKEN_LOGIN,
  ];

  const unprotectedPathRoutes = [
    '/signatory',
    '/observer',
    '/vobo',
    '/identity',
    '/retry/biometric',
    '/admin',
    '/auth/nmp/callback',
    '/auth/teran/callback',
    '/users/action',
    '/users/setup',
    '/facelogin/identity',
  ];

  const accessRoutes = [
    screenPathConstants.HOME,
    screenPathConstants.LOGIN,
    screenPathConstants.REGISTER,
    screenPathConstants.LANDING_PAGE,
  ];

  const containsUnprotectedPathRoutes = unprotectedPathRoutes.filter((path) => router.pathname.startsWith(path));
  const containsUnprotectedRoutes = unprotectedRoutes.filter((path) => router.pathname.startsWith(path));
  const isPathProtected = containsUnprotectedRoutes.length === 0 && containsUnprotectedPathRoutes.length === 0;
  const isAccessRoutes = accessRoutes.indexOf(router.pathname) >= 0;
  const [ isProcessing, setIsProcessing ] = useState(true);
  const [ isRedirectedPage, setIsRedirectedPage ] = useState(false);

  useEffect(() => {
    validateProtectedUrl();
  }, [router.pathname]);

  const validateProtectedUrl = () => {
    const isAuthenticated = LocalStorageRepository.getAccessToken() && auth.user && Object.keys(auth.user).length > 0;
    if (!isAuthenticated && isPathProtected && !router.pathname.includes(screenPathConstants.LOGIN) ) {
      setIsRedirectedPage(true);
      window.location.href = screenPathConstants.LOGIN;
      return;
    }
    if (isAuthenticated && isAccessRoutes) {
      const searchParams = new URLSearchParams(document.location.search);
      const paramsKeys = getAllParams(searchParams);
      if (paramsKeys.includes('redirectDocumentPage')) {
        const actionPath = searchParams.get('action');
        const actionCode = searchParams.get('code');
        if (actionPath && actionCode) {
          setIsRedirectedPage(true);
          const redirectPath = `/document/${actionPath}/${actionCode}`;
          window.location.href = redirectPath;
          return;
        }
      }
      setIsRedirectedPage(true);
      window.location.href = screenPathConstants.DOCUMENT_HISTORY;
      return;
    }
    setIsProcessing(false);
    setIsRedirectedPage(false);
  };

  return (
    <>
      {(isProcessing || isRedirectedPage) && <Loader isPageLoader />}
      {!isProcessing && !isRedirectedPage && children}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
