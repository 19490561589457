import React from 'react';
import { FormattedMessage } from 'react-intl';

import MembershipCard from '../../atoms/membership-card.component';

const SuscriptionNowNotice = () => {
  return (
    <div className="plans-notice">
      <div className="plans-notice__introduction">
        <div className="plans-notice__title">
          <span><FormattedMessage id="freeplan.payment.suscription.title" /></span>
        </div>
        <div className="plans-notice__subtitle">
          <span className="plans-notice__subtitle--large"><FormattedMessage id="freeplan.payment.suscription.subtitle" /></span>
        </div>
      </div>
      <div className="plans-notice__content">
        <MembershipCard />
      </div>
      <div className="plans-notice__bottom">
        <div className="plans-notice__suscription">
          <FormattedMessage id="freeplan.payment.notice.suscription.expired" />
        </div>
      </div>
    </div>
  );
};

export default SuscriptionNowNotice;
