import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';

import { setLocale } from '../../actions/index';
import { changeLanguage } from '../../utils/index';
import { languageConstant } from '../../factories/constant';

const LangSelector = ({setLocale}) => {
  const [currentLang, setCurrentLang] = useState(localStorage.englishLang ?? languageConstant.ES);

  useEffect(() => {
    handleChangeLang(currentLang);
  }, [currentLang]);

  const handleChangeLang = (lang) => {
    setLocale(lang);
    changeLanguage(lang);
  };
  
  return (
    <div className="lang-selector">
      <div className={`lang-selector__item ${currentLang === languageConstant.ES ? 'lang-selector__item--current' : ''} `}>
        <button className="lang-selector__btn" type="button" onClick={()=>setCurrentLang(languageConstant.ES)}>ES</button>
      </div>
      <div className={`lang-selector__item ${currentLang === languageConstant.EN ? 'lang-selector__item--current' : '' }`}>
        <button className="lang-selector__btn" type="button" onClick={()=>setCurrentLang(languageConstant.EN)}>EN</button>
      </div>
    </div>
  );
};

export default connect(null, {setLocale})(LangSelector);
