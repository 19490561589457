import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import { connect } from 'react-redux';

import { notificationDialogType } from '../../factories/constant';
import { cleanCustomDialog } from '../../actions';

const DialogCustom = ({
  isOpen,
  type,
  onClose,
  className,
  hasCustomClose,
  closeElement,
  children,
  headerElement,
  cleanCustomDialog,
  onEntered,
}) => {
  const [statusModal, setStatusModal] = useState(false);
  
  useEffect(() => {
    setStatusModal(isOpen);
  }, [isOpen]);

  const handleCloseDialog = (event, reason) => {
    if (hasCustomClose) {
      return false;
    }
    if (type === notificationDialogType.DIALOG) {
      if (reason === "backdropClick") {
        return false;
      }
      if (reason === "escapeKeyDown") {
        return false;
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setStatusModal(false);
    cleanCustomDialog();
    onClose();
  };

  return (
    <>
      <Dialog
        open={statusModal}
        className={`wt-dialog ${className ?? ''}`}
        onClose={handleCloseDialog}
        onEntered={onEntered}
      >
        {headerElement}
        <DialogContent className="wt-dialog__container">
          <>
            {type !== notificationDialogType.DIALOG && !hasCustomClose && (
              <div className="wt-dialog__close" onClick={()=>handleClose()}>
                <i className="wt-icon-close" />
              </div>
            )}
            {hasCustomClose && (
              {closeElement}
            )}
            {children}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

DialogCustom.defaultProps = {
  isOpen: false,
  type: notificationDialogType.NOTICE,
  className: '',
  hasCustomClose: false,
  onClose: () => {},
  children: <></>,
  closeElement: <></>,
  headerElement: <></>,
  onEntered: () => {},
}

DialogCustom.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  hasCustomClose: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.element,
  closeElement: PropTypes.element,
  headerElement: PropTypes.element,
  onEntered: PropTypes.func,
}

export default connect(null, {cleanCustomDialog})(DialogCustom);
