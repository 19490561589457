import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const PlanPromoNotice = ({user}) => {
  return (
    <div className="plan-promo">
      <div className="plan-promo__introduction">
        {user && user.isNewUser ? (
          <>
            <div className="plan-promo__introduction__title">
              <FormattedMessage id="payment.promo.new.title" />
              <div className="plan-promo__introduction__title--light">
                <FormattedMessage id="payment.promo.new.subtitle" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="plan-promo__introduction__title plan-promo__introduction__title--interlined">
              <FormattedMessage id="payment.promo.title" />
            </div>
            <div className="plan-promo__introduction__subtitle plan-promo__introduction__subtitle--small">
              <FormattedMessage id="payment.promo.subtitle" />
            </div>
          </>
        )}
      </div>
      <div className="plan-promo__content">
        <div className="plan-promo__graphic"><img src="/images/promo-gift.svg" alt="Promo Descuento" /></div>
        <div className="plan-promo__discount">
          {user && user.isNewUser ? (
            <div className="plan-promo__discount__text">
              <FormattedMessage id="payment.promo.discount.new.title" />
            </div>
          ) : (
            <div className="plan-promo__discount__text">
              <FormattedMessage id="payment.promo.discount.title" />
            </div>
          )}
          <div className="plan-promo__discount__number">
            <FormattedMessage id="payment.promo.discount.percent" />
          </div>
        </div>
      </div>
      <div className="plan-promo__description">
        {user && user.isNewUser ? (
          <div className="plan-promo__description__title">
            <FormattedMessage id="payment.promo.discount.new.subtitle" />
            <strong><FormattedMessage id="payment.promo.discount.months" /></strong>
          </div>
        ) : (
          <div className="plan-promo__description__title">
            <FormattedMessage id="payment.promo.discount.subtitle" />
            <strong><FormattedMessage id="payment.promo.discount.months" /></strong>
          </div>
        )}
        <div className="plan-promo__description__subtitle">
          <FormattedMessage id="payment.promo.discount.cta" />
        </div>
      </div>
    </div>
  );
};

PlanPromoNotice.defaultProps = {
  user: null,
};

PlanPromoNotice.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user && state.user.user ? state.user.user : null,
});

export default connect(mapStateToProps)(PlanPromoNotice);
