import React from 'react';
import { FormattedMessage } from 'react-intl';

const DocumentRestrictedDialog = () => {
  return (
    <div className="document-restricted-dialog wt-dialog--center">
      <div className="wt-dialog__graphic wt-dialog--start">
        <img src="images/document-restricted.svg" alt="document restricted" />
      </div>
      <div className="wt-dialog__title">
        <FormattedMessage id="document.restricted.dialog.title" />
      </div>
      <div className="wt-dialog__caption wt-dialog--end">
        <FormattedMessage id="document.restricted.dialog.description" />
      </div>
    </div>
  );
};

export default DocumentRestrictedDialog;
