import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {Typography} from '@material-ui/core';
import {Column, Row} from 'simple-flexbox';

import {genericConstants} from '../../factories/constant';
import Loader from '../../components/atoms/loader.component';
import RecoveryPassword from '../../components/molecules/auth/recovery-password.component';
import RecoveryPasswordConfirmation from '../../components/molecules/auth/recovery-password-confirmation.component';
import ResetPassword from '../../components/molecules/auth/reset-password.component';
import DialogCustom from '../../components/organisms/dialog-custom.component'

const VerifyEmailContent = styled.div`
    font-size: 18px;
    margin-bottom: 15px;
`;

const VerifyEmailContentTitle = styled(VerifyEmailContent)`
    font-weight: bold;
`;

const VerifyEmailHightlight = styled(VerifyEmailContent)`
    color: #0ba280;
    font-weight: bold;
`;

const VerifyEmailHelp = styled.div`
    font-size: 12px;
    line-height: 1.5
    margin-bottom: 15;
`;

const verifyEmailDialog = (props, dialogType = genericConstants.EMAIL_DIALOG_TEMPLATE.SEND_VERIFY_EMAIL, isLoginScreen = true) => {

    return (
        <>
            {isLoginScreen ?
                <DialogCustom
                    isOpen={props.openVerifyEmailPopup}
                    onClose={props.closeVerifyEmailPopup}
                >
                    <>
                        {showContent(props, dialogType)}
                        {props.btnLoader && <Loader isPageLoader isTransparent />}
                    </>
                </DialogCustom>
            :
                <>
                    {showContent(props, dialogType)}
                    {props.btnLoader && <Loader isPageLoader isTransparent />}
                </>
            }
        </>
    );
};

function showContent(props, dialogType) {
    switch (dialogType) {
        case genericConstants.EMAIL_DIALOG_TEMPLATE.SEND_VERIFY_EMAIL:
            return verifyEmailContent(props);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.RE_VERIFY_EMAIL:
            return verifyEmailContent(props, dialogType);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.RESEND_EMAIL_SUCCESS:
            return resendEmailSuccess(props);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_SUCCESS:
            return verifyEmailSuccessContent(props);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_FAILURE:
            return verifyEmailFailureContent(props, dialogType);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.RESET_PASSWORD_MAIL:
            return resetPasswordContent(props);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.RESET_PASSWORD_FAILURE:
            return verifyEmailFailureContent(props, dialogType);
        case genericConstants.EMAIL_DIALOG_TEMPLATE.FORGOT_PASSWORD:
            return forgotPasswordContent(props, dialogType);
        default:
            return null;
    }
}

function verifyEmailContent(props, dialogType = "") {
    return (
        <Column horizontal={'center'} className={'margin-0-auto pr-4 pl-4 pb-md width-85-per'}>
            <Row style={{flex:"1 0 0%"}} horizontal={'space-between'}>
                {dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.RE_VERIFY_EMAIL ?
                    <Typography className="text-center font-color-darkslategray  p-3 font-header"><FormattedMessage
                        id='register.verify_email.success.heading'/></Typography> :
                    <Typography className="text-center font-color-darkslategray fs-28 p-3"><FormattedMessage
                        id='register.success.heading'/></Typography>}
            </Row>
            <Row horizontal={'space-between'}>
                <img src="images/send-icon.png" className="img-responsive" alt="send" />
            </Row>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray fs-20 p-3">
                    {dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.RE_VERIFY_EMAIL ? (
                        <>
                            <VerifyEmailContent>
                                <FormattedMessage id='login.email_not_verified'/>
                            </VerifyEmailContent>
                            <VerifyEmailHelp>
                                <FormattedMessage id="not_received.message"/>&nbsp;
                                <a
                                    className="font-color-aquamarine"
                                    onClick={props.resendVerifyEmail}
                                >
                                    <FormattedMessage id='click_here.message'/>
                                </a>
                            </VerifyEmailHelp>
                        </>
                    ) : (
                        <React.Fragment>
                            <VerifyEmailContentTitle>
                                <FormattedMessage id='register.success.message.welcome'/>
                            </VerifyEmailContentTitle>
                            {(props.user && props.user.emailID) && (
                                <React.Fragment>
                                    <VerifyEmailContent>
                                        <FormattedMessage id='register.success.message.welcome.description'/>
                                    </VerifyEmailContent>
                                    <VerifyEmailHightlight>
                                        <span>{props.user.emailID}</span>
                                    </VerifyEmailHightlight>
                                    <VerifyEmailHelp>
                                        <FormattedMessage id="not_received.message"/>&nbsp;
                                        <a
                                            className="font-color-aquamarine"
                                            onClick={props.resendVerifyEmail}
                                        >
                                            <FormattedMessage id='click_here.message'/>
                                        </a>
                                    </VerifyEmailHelp>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Typography>
            </Row>
        </Column>
    )
}

function verifyEmailSuccessContent(props) {
    return (
        <Column horizontal={'center'} className={'margin-0-auto pr-4 pl-4 pt-2 pb-4 w-75'}>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray  font-header w-max-content"><FormattedMessage
                    id='register.verify_email.success.heading'/></Typography>
            </Row>
            <Row horizontal={'space-between pt-md'}>
                <img src="images/tick-icon.png" className="img-responsive" alt="tick"/>
            </Row>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray font-header p-3 "><FormattedMessage
                    id='register.verify_email.success.message'/></Typography>
            </Row>
            <Row horizontal={'space-between'}>
                <button onClick={props.closeVerifyEmailPopup}
                        className="submit-btn font-color-white bg-Color-green-cyan">
                    <FormattedMessage id="login.submit"/>
                </button>
            </Row>

        </Column>
    )
}

function resendEmailSuccess(props) {
    return (
        <Column horizontal={'center'} className={'margin-0-auto pr-4 pl-4 pb-4 w-75'}>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray  p-3 font-header"><FormattedMessage
                    id='register.verify_email.success.heading'/></Typography>
            </Row>
            <Row horizontal={'space-between'}>
                <img src="images/tick-icon.png" className="img-responsive" alt="tick"/>
            </Row>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray fs-20 p-3 "><FormattedMessage
                    id='register.verify_email.resend.message'/></Typography>
            </Row>
            <Row horizontal={'space-between'}>
                <button onClick={props.closeVerifyEmailPopup}
                        className="submit-btn font-color-white bg-Color-green-cyan">
                    <FormattedMessage id="login.submit"/>
                </button>
            </Row>

        </Column>
    )
}

function verifyEmailFailureContent(props, dialogType) {
    return (
        <Column horizontal={'center'} className={'margin-0-auto pr-4 pl-4 pb-4 pt-2 width-85-per '}>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray  padding-container font-header">
                    {dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_FAILURE
                        ? <FormattedMessage id='register.verify_email.success.heading'/> :
                        dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.RESET_PASSWORD_FAILURE
                            ? <FormattedMessage id='reset.password.heading'/> : ""
                    }
                </Typography>
            </Row>
            <Row horizontal={'space-between'}>
                <img src="images/cancel.png" className="img-responsive" alt="cancel"/>
            </Row>
            <Row horizontal={'space-between'}>
                <Typography className="text-center font-color-darkslategray fs-20 p-3 "><FormattedMessage
                    id='register.verify_email.failure.message'/></Typography>
            </Row>
            <Row horizontal={'space-between'}>
                <button onClick={props.closeVerifyEmailPopup}
                        className="submit-btn font-color-white bg-Color-green-cyan">
                    <FormattedMessage id="login.submit"/>
                </button>
            </Row>

        </Column>
    )
}

function resetPasswordContent(props) {
    return (
        <>
            <ResetPassword
                titleId={!props.createdByPayment ? 'reset.password.heading' : 'reset.payment.password.heading'}
                subtitleId={!props.createdByPayment ? 'reset.password.subheading' : 'reset.payment.password.subheading'}
                onReset={props.onFormSubmit}
                errorPassword={props.passwordError}
            />
        </>
    )
}

function forgotPasswordContent(props) {
    return (
        <>
            {!props.isForgotPasswordSuccess ?
                <RecoveryPassword onChange={props.handleChange} onRecovery={props.resetPassword} />
            :
                <RecoveryPasswordConfirmation onResend={props.resendResetPassword} onConfirm={props.closeVerifyEmailPopup} />
            }
        </>
    )
}

export default verifyEmailDialog;
