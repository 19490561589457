import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const RecoveryPasswordConfirmation = ({ onResend, onConfirm}) => {
  return (
    <div className="recovery-password recovery-password--confirm">
      <div className="recovery-password__graphic">
        <img src="images/password-recovery.svg" alt="Recovery Password" />
      </div>
      <div className="recovery-password__title wt-dialog__title">
        <FormattedMessage id="forgot_password_email_sent" />
      </div>
      <div className="recovery-password__line wt-dialog__line-green">
        <img src="images/dialog-line.svg" alt="Recovery Password Line" />
      </div>
      <div className="recovery-password__message wt-dialog__message">
        <FormattedMessage id="forgot_password.success.message" />
      </div>
      <div className="recovery-password__caption wt-dialog__caption">
        <FormattedMessage id="not_received.message" />
        <a className="link-theme" onClick={onResend}>
          <FormattedMessage id="click_here.message" />
        </a>
      </div>
      <div className="recovery-password__actions wt-dialog__actions wt-dialog--end">
        <button
          onClick={onConfirm}
          className="wt-btn wt-btn--circle wt-btn--theme"
        >
          <FormattedMessage id="login.submit"/>
        </button>
      </div>
    </div>
  );
};

RecoveryPasswordConfirmation.defaultProps = {
  onResend: () => {},
  onConfirm: () => {},
};

RecoveryPasswordConfirmation.propTypes = {
  onResend: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default RecoveryPasswordConfirmation;
