import React from 'react';
import PropTypes from 'prop-types';

import LangSelector from '../molecules/lang-selector.component';
import Footer from '../organisms/footer.component';

const BaseLayout = ({showLang, children, className}) => {
  return (
    <div className={`base-layout ${className ?? ''}`}>
      <div className="base-layout__header">
        {showLang && <LangSelector />}
      </div>
      <div className="base-layout__container">
        {children}
        <Footer />
      </div>
    </div>
  );
};

BaseLayout.defaultProps = {
  className: '',
  showLang: false,
  children: <></>,
};

BaseLayout.propTypes = {
  className: PropTypes.string,
  showLang: PropTypes.bool,
  children: PropTypes.element,
};

export default BaseLayout;
