import React from 'react';
import { FormattedMessage } from 'react-intl';

const UpdatePaymentMethodsNotice = () => {
  return (
    <div className="plans-notice">
      <div className="plans-notice__introduction">
        <div className="plans-notice__title">
          <div className="membership-card__graphic mb-4">
            <img src="/images/logo-with-shadow.svg" alt="Membresía Información" />
          </div>
          <span>
            <FormattedMessage id="payment.method.update.title" />
          </span>
        </div>
      </div>
      <div className="plans-notice__description plans-notice__payment">
        <FormattedMessage id="payment.method.update.description" />
      </div>
    </div>
  );
};

export default UpdatePaymentMethodsNotice;
