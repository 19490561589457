import React from 'react';
import { FormattedMessage } from 'react-intl';

const DinamicMembershipNotice = () => {
  return (
    <div className="dynamic-membership">
      <div className="dynamic-membership__container">
        <div className="dynamic-membership__title">
          <FormattedMessage id="dinamic.membership.title" />
        </div>
        <div className="dynamic-membership__graphic">
          <img src="/images/wt-star.svg" alt="Moverme a Membresía dinámica" />
        </div>
        <div className="dynamic-membership__message">
          <FormattedMessage id="dinamic.membership.message" />&nbsp;<br />
          <a href="https://www.weetrust.mx/precios" className="link-theme" target="_blank" rel="noopener noreferrer"><FormattedMessage id="dinamic.membership.message.link" /></a>&nbsp;
          <FormattedMessage id="dinamic.membership.message.date" />
        </div>
      </div>
    </div>
  )
};

export default DinamicMembershipNotice;
