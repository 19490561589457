import React, { Fragment, useEffect } from 'react';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Notifier, {openSnackbar} from '../modules/common/notification';

const hocAux = (WrappedComponent) => {  class HOC extends React.Component {
    render() {
      return (
        <MuiThemeProvider>
          <Fragment>
            <Notifier/>
            <WrappedComponent {...this.props} openSnackbar={openSnackbar} />
          </Fragment>
        </MuiThemeProvider>
      );
    }
  }

  return HOC;
};

export default hocAux;
