import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ExpiryModal = ({ onClose }) => {
  return (
    <div className="expiry-session wt-dialog--center">
      <div className="expiry-session__title wt-dialog__title wt-dialog--start">
        <FormattedMessage id="expiry.modal.title" />
      </div>
      <div className="expiry-session__content wt-dialog__message">
        <FormattedMessage id="expiry.modal.description" />
      </div>
      <div className="expiry-session__actions wt-dialog__actions wt-dialog--end">
        <button onClick={() => onClose()} className="wt-btn wt-btn--circle wt-btn--theme">
          <FormattedMessage id="expiry.modal.button" />
        </button>
      </div>
    </div>
  );
};

ExpiryModal.propTypes = {
  onClose: PropTypes.func,
};

ExpiryModal.defaultProps = {
  onClose: () => {},
};

export default ExpiryModal;
