import $ from 'jquery';
import path from 'path';
import request from 'request';
import { genericConstants, routesBlockedByPermissions } from '../factories/constant';
import {history} from "./history";
import LinesEllipsis from "react-ellipsis-text";
import React from "react";
import moment from "moment";
import _ from 'lodash';

export const lhtLogs = (msg, payload, type) => {
    type = type || 'info';
    payload = payload || {};

    switch (type) {
        case 'info':
            console.log('%c ' + msg, 'color: #808080');
            break;
        case  'warning':
            console.log('%c ' + msg, 'color: #FF4500');
            break;
        case 'error':
            console.log('%c ' + msg, 'color: red');
            break;
        default:
            console.log(msg)
    }
    console.log(payload);
};

export const getCountries = () => {
    return $.getJSON('asset/countries.json', {});
};

export const checkPassword = (password) => {
    // To check a password between 8 to 15 characters which contain at least one lowercase letter,
    // one uppercase letter, one numeric digit, and one special character
    return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/);
};

export const getNewFileName = (baseName, extension) => {
    const fileName = `${baseName}_${new Date().getTime()}`.replace(/ /g, "_")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
        .replace(new RegExp("\\s", 'g'),"")
        .replace(new RegExp("[àáâãäå]", 'g'),"a")
        .replace(new RegExp("æ", 'g'),"ae")
        .replace(new RegExp("ç", 'g'),"c")
        .replace(new RegExp("[èéêë]", 'g'),"e")
        .replace(new RegExp("[ìíîï]", 'g'),"i")
        .replace(new RegExp("ñ", 'g'),"n")
        .replace(new RegExp("[òóôõö]", 'g'),"o")
        .replace(new RegExp("œ", 'g'),"oe")
        .replace(new RegExp("[ùúûü]", 'g'),"u")
        .replace(new RegExp("[ýÿ]", 'g'),"y")
        .replace(new RegExp("\\W", 'g'),"");

    return `${fileName}${extension}`;
};

export const sanitize = (str, type) => {
  switch (type) {
    case 'digit': {
      return str.replace(/[^0-9]/g, '');
    }
    case 'nickname': {
      return str.replace(/[^a-zA-Z0-9-_/. ]/g, '');
    }
    case 'names': {
      return str.replace(/[^a-zA-Z ]/g, '');
    }
    case 'empty': {
      const newString = str;
      return str.trim() === '' ? '' : newString;
    }
    default: {
      return str.replace(/[^a-zA-Z0-9 ]/g, '');
    }
  }
};

export const getFileName = (fileName) => {
    let extension = path.extname(fileName);
    let baseName = path.basename(fileName, extension);
    return {baseName, extension}
};

export const getFileType = (fileName) => {
    let {name, extension} = getFileName(fileName);
    return `${(extension.split(".")[1]).toUpperCase()}`;
};
export const getFileSize = (fileSize) => {
    let size = "";
    if (fileSize < 1000)
        size = `${fileSize.toFixed(1)} Byte`;
    else if (fileSize > 1000 && fileSize < 1000 * 1000)
        size = `${(fileSize / 1000).toFixed(1)} KB`;
    else if (fileSize > 1000 * 1000)
        size = `${(fileSize / 1000 / 1000).toFixed(1)} MB`;
    return size;
};

function downloadPage(url) {
    return new Promise((resolve, reject) => {
        request(url, (err, response, body) => {
            if (err)
                reject(err);
            resolve(response.body);
        });

    });
}

function Android() {
    return navigator.userAgent.match(/Android/i);
}

function BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
}

function iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
}

function Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
}

function Windows() {
    return navigator.userAgent.match(/IEMobile/i);
}

export const any = () => {
    return (BlackBerry() || Android() || iOS() || Opera() || Windows());
}
export const encodeToBase64 = async (url) => {

    var resultA = await downloadPage(url);

    var fileBuf = new Buffer(resultA, 'binary').toString('base64');
    return fileBuf;

    // // read binary data
    // var bitmap = fs.readFileSync(file);
    // // convert binary data to base64 encoded string
    // return new Buffer(bitmap).toString('base64');
}

export const insertDraggableInputFiled = async (_this, inputType, imageUrl = "") => {
    switch (inputType) {
        case genericConstants.FIELD_TYPE.DATE:
            await _this.setState({draggableInputFieldArray: [..._this.state.draggableInputFieldArray, getDraggableInputFieldArray(inputType, inputType, "preview.file.field.signatory_name")]});
            break;
        case  genericConstants.FIELD_TYPE.TEXT:
            await _this.setState({draggableInputFieldArray: [..._this.state.draggableInputFieldArray, getDraggableInputFieldArray(inputType, inputType, "preview.file.field.signatory_name")]});
            break;
        case  genericConstants.FIELD_TYPE.CHECK_MARK:
            await _this.setState({draggableInputFieldArray: [..._this.state.draggableInputFieldArray, getDraggableInputFieldArray(inputType, inputType, "preview.file.field.signatory_name")]});
            break;
        case  genericConstants.FIELD_TYPE.SIGNATURE:
            await _this.setState({draggableInputFieldArray: [..._this.state.draggableInputFieldArray, getDraggableInputFieldArray(inputType, inputType, "preview.file.field.signatory_name", imageUrl)]});
            break;
        default:
            lhtLogs("incorrect input type");

    }

};


function getDraggableInputFieldArray(name, type, placeholder, value = "") {
    if (type === genericConstants.FIELD_TYPE.DATE)
        value = {date: '', timestamp: ''};
    else if (type === genericConstants.FIELD_TYPE.CHECK_MARK)
        value = false;
    return {
        value: value,
        name: name,
        ref: null,
        type: type,
        placeholder: placeholder,
        xCoordinate: "0%",
        yCoordinate: "0%",
        isSelected: false
    };
};

export const backScreen = () => {
    history.goBack()
};
export const parseSubUserList = (requestObj) => {
    if (!requestObj || !Object.keys(requestObj).length)
        return;
    let userListArr = [];
    for (var key in requestObj) {
        userListArr.push(requestObj[key])
    }
    return userListArr;
};
export const parseCardDetails = (requestObj) => {
    if (!requestObj || !Object.keys(requestObj).length)
        return;
    let last4 = requestObj.card_number.substr(requestObj.card_number.length - 4, 4);
    return {
        cardID: requestObj.id,
        last4: last4,
        name: requestObj.holder_name,
        exp_year: requestObj.expiration_year,
        exp_month: requestObj.expiration_month,
        brand: requestObj.brand,
        addressLine1: requestObj.line1,
        addressLine2: requestObj.line2,
    }
};
export const elapsisFileName = (name, trimLength = 10) => {
    let {baseName, extension} = getFileName(name);
    if (baseName.length < trimLength)
        return name;
    return (<><LinesEllipsis
        text={baseName}
        length={trimLength + 1}
    />{`${extension}`}</>);
};
export const getApiKey = (name) => {
    if (!name || !name.length)
        return "";

    return new Buffer(name, 'base64').toString('ascii');
};

export const openChatPopup = () => {
    window.drift.api.openChat();
};

export const changeLanguage = (language) => {
    let driftConfig = {
        locale: 'es-mx',
        messages: {
            welcomeMessage: genericConstants.DRIFT_WELCOME_MESSAGE_ES,
            awayMessage: genericConstants.DRIFT_WELCOME_MESSAGE_ES
        },
    };

    if (language === 'en') {
        driftConfig = {
            locale: 'en-US',
            messages: {
                welcomeMessage: genericConstants.DRIFT_WELCOME_MESSAGE,
                awayMessage: genericConstants.DRIFT_WELCOME_MESSAGE
            }
        };
    }

    window.drift.config(driftConfig);
};

export const getNotificationIcon = (notificationType) => {
    switch (notificationType) {
        case genericConstants.NOTIFICATION_TYPE.SUCCESS:
            return 'images/success.png';
        case genericConstants.NOTIFICATION_TYPE.ERROR:
            return 'images/failure.png';
        case genericConstants.NOTIFICATION_TYPE.WARNING:
            return 'images/info-icon.png';
        case genericConstants.NOTIFICATION_TYPE.INFO:
            return 'images/info-icon.png';
        default:
            return 'images/info-icon.png';
    }
};


export const encodeBase64 = function(data) {
    if (!data)
        return false;
    return Buffer.from(data).toString('base64');
};

export const decodeBase64 = function(data) {
    if (!data)
        return false;
    let buff = Buffer.from(data, 'base64');
    return buff.toString('ascii');
};

export const getBrowser = () => {
    if(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
        return 'Chrome';
    } else if((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
        return 'Opera';
    } else if(false || !!document.documentMode) {
        return 'IE';
    } else if (((false || !!document.documentMode) && !!window.StyleMedia) || (((false || !!document.documentMode) && !!window.StyleMedia) && (navigator.userAgent.indexOf("Edg") !== -1))) {
        return 'EDGE';
    } else if(typeof InstallTrigger !== 'undefined') {
        return 'Firefox';
    } else if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window.safari.pushNotification))) {
        return 'Sarafi'
    } else {
        return 'unknown';
    }
};

export const getPrivacyGeolocationURL = (browser, lang) => {
    switch (browser) {
        case 'Chrome': {
            return `https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DDesktop&hl=${lang}`;
        }
        case 'Opera': {
            return `https://help.opera.com/${lang}/geolocation/`;
        }
        case 'IE': {
            return `https://support.microsoft.com/${lang}-${lang}/help/17479/windows-internet-explorer-11-change-security-privacy-settings`;
        }
        case 'EDGE': {
            return `https://support.microsoft.com/${lang}-${lang}/help/4468240/windows-10-location-service-and-privacy`;
        }
        case 'Firefox': {
            return `https://support.mozilla.org/${lang}-${lang}/kb/does-firefox-share-my-location-websites`;
        }
        case 'Sarafi': {
            return `https://support.apple.com/${lang}-${lang}/HT5403`;
        }
        default: {
            return 'https://google.com';
        }
    }
};

export const fetchAsBlob = url => fetch(url)
    .then(response => response.blob());

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
    })
}

export const getStringDateEs = (date) => {
    const appendLeadingZeroes = (n) => (n < 10) ? `0${n}` : n;
    const day = appendLeadingZeroes(date.getDate());
    const month = appendLeadingZeroes(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const getFileTypeImageURL = (fileType) => {
    let url = '';
    if (fileType === 'DOCX')
        url = 'icon-document-menu icon-doc-validate';
    else if (fileType === 'DOC')
        url = 'icon-document-menu icon-doc-validate';
    else if (fileType === 'PDF')
        url = 'icon-pdf icon-doc-validate';
    return url;
};

export const getTemplateData = (data, type) => {
    if(!data) return {error: 'templates.errors.100'};

    let result = [];
    if(type === 'form') {
        let values = {};
        Object.keys(data).forEach( key => {
            const row = data[key];
            let value = row.value;
            if(data[key].type === 'date') {
                value = moment(value).format('DD-MM-YYYY');
            }
            values[row.key] = value;
        });
        result.push(values);
    } else if(type === 'csv') {
        let headers = [];
        data.forEach( (row, i) => {
            let values = {};
            if(i > 0) {
                if(headers.length !== row.length) {
                    //Skip Blank Lines
                    if(row.length === 1) {
                        return;
                    }
                }
            }
            row.forEach ( (val, j) => {
                if( i === 0) {
                    headers.push(val);
                } else {
                    values[headers[j]] = val;
                }

                if( j === 0) {
                    values.email = val;
                }
            })
            if( i !== 0) {
                result.push(values);
            }
        });
        if(!headers[0] || headers[0].localeCompare('email') !== 0 || !headers[1] || headers[1].localeCompare('name') !== 0 ) {
            return {error: 'templates.errors.101'};
        }

        const regEmailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let emailCount = 0;
        let nameCount = 0;

        for(let i=0; i < result.length; i++) {
            const emailsSplit = result[i]['email'].split(";");
            const namesSplit = result[i]['name'].split(";");
          const phoneSplit = _.chain(result[i])
            .get('whatsapp', '')
            .split(';')
            .value();

            let emails = emailsSplit.filter( email => email.length > 0);
            let names = namesSplit.filter( name => name.length > 0);
          const phones = phoneSplit.filter((phone) => phone.length > 0);

            if(!emails && emails.length === 0) {
                return {error: 'templates.errors.102'};
            }

            if(!names && names.length === 0) {
                return {error: 'templates.errors.103'};
            }

            if(i == 0) {
                emailCount = emails.length;
                nameCount = names.length;
            }

            if( emails.length !== names.length || emails.length !== emailCount || names.length !== nameCount) {
                return {error: 'templates.errors.105'};
            }

            let signatories = [];

            for(let j=0; j < emails.length; j++) {
                if ( emails[j].trim().length === 0) {
                    return {error: 'templates.errors.102'};
                }

                if (!regEmailValidation.test(emails[j].trim())) {
                    console.log("email error =>",emails[j].trim())
                    return {error: 'templates.errors.104'};
                }

              if (phones[j] && phones[j].length > 0 && !validateWhatsAppNumber(phones[j].trim())) {
                return { error: 'templates.errors.111' };
              }

              const phone = phones[j] && phones[j].length > 0 ? validateWhatsAppNumber(phones[j].trim()) ? phones[j].trim() : '' : '';

              signatories.push({
                    email: emails[j].trim(),
                    name: names[j].trim(),
                phone: phone && phone.length > 0 && !phone.startsWith('+') ? `+${phone}` : phone,
                })
            }

            result[i]['signatories'] = signatories;

        }
    }

    return result;
};

export const removeSavedDocument = ({ savedDocument, documentType }) => {

    if (savedDocument && savedDocument.signatory && savedDocument.signatory.length && savedDocument.documentType !== documentType) {
        return {
            signatory: [],
            sharedWith: [],
            staticSignPositions: [],
            isActiveGeolocation: false,
            title: '',
            message: '',
            hasOrder: false,
            documentType: '',
            documentSignType: '',
        };
    }

    return savedDocument;
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const getSelectOptions = (options) => {
    try{
        let selectOptions = [];
        if (options && options.length > 0) {
            selectOptions = options.map(function(option) {
                if (option && Object.keys(option).length > 1) {
                    return {
                        value: option[Object.keys(option)[0]],
                        label: option[Object.keys(option)[1]]
                    }
                }
            });
        }
        return selectOptions;
    } catch(err) {
        console.log(err);
        return [];
    }
}

export const getCurrentGeolocation = () => {
  if (navigator.geolocation) {
    return new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
};

export const changeTheme = (color) => {
    //Change localvariables
    document.body.style.setProperty('--theme-color', color);
    document.body.style.setProperty('--loader-color-1', color);
    document.body.style.setProperty('--loader-color-2', hex2rgba(color, 0.75));
    document.body.style.setProperty('--loader-color-3', hex2rgba(color, 0.5));
    document.body.style.setProperty('--loader-color-4', hex2rgba(color, 0.25));

    //Change variables de weesign/biometric
    document.body.style.setProperty('--wsvid-theme-color', color);
    document.body.style.setProperty('--wsvid-loader-color-1', color);
    document.body.style.setProperty('--wsvid-loader-color-2', hex2rgba(color, 0.75));
    document.body.style.setProperty('--wsvid-loader-color-3', hex2rgba(color, 0.5));
    document.body.style.setProperty('--wsvid-loader-color-4', hex2rgba(color, 0.25));
};


export const ignoreEnterKey = (e) => {
    if (e.key === 'Enter') e.preventDefault();
};

const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

export const getPagesFromList = (list, pageLimit = 15) => {
    const counter = list.length;
    const pagesRatio = counter / pageLimit;
    return pagesRatio > 0 ? Math.ceil(pagesRatio) : 0;
};

export const getListByPage = (list, page, pageLimit = 15) => {
    const limit = list.length;
    const minPosition = page * pageLimit - pageLimit;
    const maxPosition = page * pageLimit;
    const extractList = list.slice(minPosition, maxPosition <= limit ? maxPosition : limit);
    return extractList;
};

export const hasPermission = (user, permissions = []) => {
    const { permissionsDenied } = user;
    let isValid = true;
    if (permissionsDenied && Array.isArray(permissionsDenied) && permissionsDenied.length > 0) {
        isValid = permissions.filter(permission => permissionsDenied.includes(permission)).length === 0;
    }
    return isValid;
};

export const isRouteAllowed = (user, currentPath) => {
    let isAllowed = true;
    if (user && currentPath) {
        const routeBlocked = routesBlockedByPermissions.find((path) => currentPath.startsWith(path.url));
        if (routeBlocked && routeBlocked.allowed) {
            if (!hasPermission(user, routeBlocked.allowed)) {
                isAllowed = false;
            }
        }
    }
    return isAllowed;
};

export const validateWhatsAppNumber = (number) => /^\+?\d{1,3}\d{9}$/.test(number.replace(/\s/g, ''));
