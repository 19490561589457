import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {withStyles} from '@material-ui/core/styles';
import {genericConstants} from "../../factories/constant";
import {Column, Row} from 'simple-flexbox';
import {FormattedMessage} from 'react-intl';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};
let openSnackbarFn;

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 22,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContent(props) {
    const {classes, className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)+ " snackbar-child"}
            aria-describedby="client-snackbar"
            message={
                <Row className="justify-content-between">
                    <Column><span id="client-snackbar" className={classes.message}><Icon className={classNames(classes.icon, classes.iconVariant)}/> {message}</span></Column>
                    <Column>
                        <div>
                            <CloseIcon className={classes.icon} onClick={onClose}/>
                        </div>
                    </Column>
                    {/*<span id="client-snackbar" className={classes.message}>*/}
          {/*<Icon className={classNames(classes.icon, classes.iconVariant)}/>*/}
                    {/*{message}*/}
        {/*</span>*/}
                </Row>
            }
            action={[

            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
});

class CustomizedSnackbars extends React.Component {
    state = {
        open: false,
        notificationType: genericConstants.TOAST_TYPE.SUCCESS,
        message: ""
    };

    componentDidMount() {
        openSnackbarFn = this.handleClick;
    }

    handleClick = (message, notificationType) => {
        this.setState({open: true, message: message, notificationType: notificationType});
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.open}
                    autoHideDuration={4000}
                    onClose={this.handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.state.notificationType}
                        className={classes.margin}
                       // message={this.state. message}
                        message={<div> <FormattedMessage id={this.state.message}/></div>}

                    />
                </Snackbar>
                {/*<MySnackbarContentWrapper*/}
                {/*variant="error"*/}
                {/*className={classes.margin}*/}
                {/*message="This is an error message!"*/}
                {/*/>*/}
                {/*<MySnackbarContentWrapper*/}
                {/*variant="warning"*/}
                {/*className={classes.margin}*/}
                {/*message="This is a warning message!"*/}
                {/*/>*/}
                {/*<MySnackbarContentWrapper*/}
                {/*variant="info"*/}
                {/*className={classes.margin}*/}
                {/*message="This is an information message!"*/}
                {/*/>*/}
                {/*<MySnackbarContentWrapper*/}
                {/*variant="success"*/}
                {/*className={classes.margin}*/}
                {/*message="This is a success message!"*/}
                {/*/>*/}
            </div>
        );
    }
}

CustomizedSnackbars.propTypes = {
    classes: PropTypes.object.isRequired,
};

export function openSnackbar(message, notificationType = genericConstants.TOAST_TYPE.SUCCESS) {
    openSnackbarFn(message, notificationType);
}

export default withStyles(styles2)(CustomizedSnackbars);
