import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Loader from '../../atoms/loader.component';
import { genericConstants, screenPathConstants } from '../../../factories/constant';

const SignupVerify = ({dialogType}) => {
  return (
    <div className="signup-verify">
      {dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL && (
        <Loader isPageLoader label='register.verify_email.success.heading' />
      )}
      {dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_SUCCESS && (
        <Loader isPageLoader label='register.verify_email.success.message' />
      )}
      {dialogType === genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_FAILURE && (
        <div className="signup-verify__fail">
          <div className="signup-verify__logo">
            <img alt="Verify logo" src="images/logo_multicolor.svg"/>
          </div>
          <div className="signup-verify__fail__title">
            <FormattedMessage id="register.verify_email.success.heading" />
          </div>
          <div className="signup-verify__fail__image">
            <img alt="Verify failed" src="images/cancel.png"/>
          </div>
          <div className="signup-verify__fail__subtitle">
            <FormattedMessage id="register.verify_email.failure.message" />
          </div>
          <div className="signup-verify__fail__actions">
            <NavLink
              to={screenPathConstants.LOGIN}
              className="signup-verify__fail__btn wt-btn wt-btn--theme wt-btn--circle"
            >
              <FormattedMessage id="signup.verify.fail.btn"/>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

SignupVerify.defaultProps = {
  dialogType: '',
};

SignupVerify.propTypes = {
  dialogType: PropTypes.string.isRequired,
};

export default SignupVerify;
