import { actionTypeConstant } from '../factories/constant';

const emptyBanner = {
  bannerId: '',
};

const initialState = emptyBanner;

export default function(state = initialState, action) {
  switch(action.type) {
    case actionTypeConstant.SHOW_BANNER_TOP: {
      return {
        bannerId: action.payload.bannerId,
      }
    }
    case actionTypeConstant.CLEAR_BANNER_TOP: {
      return emptyBanner;
    }
    default: {
      return state;
    }
  }
}
