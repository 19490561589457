import React from 'react';
import { FormattedMessage } from 'react-intl';

import MembershipCard from '../../atoms/membership-card.component';

const PlanExpiredNotice = () => {
  return (
    <div className="plans-notice">
      <div className="plans-notice__introduction">
        <div className="plans-notice__title">
          <span><FormattedMessage id="freeplan.payment.notice.title" /></span>
        </div>
        <div className="plans-notice__subtitle">
          <span className="plans-notice--warning">
            <strong><FormattedMessage id="freeplan.payment.notice.subtitle.expired.fragment_1" /><br /><FormattedMessage id="freeplan.payment.notice.subtitle.expired.fragment_2" /></strong>
          </span>
        </div>
      </div>
      <div className="plans-notice__description">
        <FormattedMessage id="freeplan.payment.notice.description" />
      </div>
      <div className="plans-notice__content">
        <MembershipCard />
      </div>
      <div className="plans-notice__bottom">
        <div className="plans-notice__suscription">
          <FormattedMessage id="freeplan.payment.notice.suscription.expired" />
        </div>
      </div>
    </div>
  );
};

export default PlanExpiredNotice;
