import {combineReducers} from 'redux';
import locale from './locale';
import user from './user';
import document from './document';
import eventEmitter from './eventEmitter';
import tag from './tag';
import invoice from './invoice';
import notification from './notification.reducer';
import dialog from './dialog.reducer';
import banner from './banner.reducer';
import products from './products.reducer';
import toast from './toast.reducer';
import endorsement from './endorsement.reducer';
import template from './template.reducer';
import onboarding from './onboarding.reducer';
import format from './format.reducer';

export default combineReducers({
  user,
  document,
  locale,
  eventEmitter,
  tag,
  invoice,
  notification,
  dialog,
  banner,
  products,
  toast,
  endorsement,
  template,
  onboarding,
  format,
});
