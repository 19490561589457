import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';

import { ignoreEnterKey } from '../../../utils';
import { formsErrorConstants, formsFormatsConstants, errorConstants, languageConstant } from '../../../factories/constant';
import messages from '../../../utils/messages';

const RecoveryPassword = ({ onRecovery, onChange }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const currentLang = localStorage && localStorage.englishLang ? localStorage.englishLang : languageConstant.ES;

  const onSubmit = (dataForm) => {
    onRecovery(dataForm.emailID);
  };

  return (
    <div className="recovery-password">
      <div className="recovery-password__title wt-dialog__title wt-dialog--start">
        <FormattedMessage id='forgot_password' />
      </div>
      <div className="recovery-password__message wt-dialog__message">
        <FormattedMessage id='forgot_password_message' />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => ignoreEnterKey(e)} className="recovery-password__form">
        <div className="recovery-password__form__group">
          <div className="recovery-password__label">
            <FormattedMessage id='login.email' />
          </div>
          <div className="recovery-password__input">
            <input
              name="emailID"
              type="text"
              className={`input-text input-text--transparent ${errors.email ? 'input-aria-invalid': ''}`}
              {...register('emailID', {
                required: { value: true, message: messages[currentLang][errorConstants.FILL_FIELD_MESSAGE] },
                maxLength: { value: 150, message: `${messages[currentLang][formsErrorConstants.MAX_LENGTH]} 150` },
                pattern: { value: formsFormatsConstants.EMAIL, message: messages[currentLang][formsErrorConstants.EMAIL_INVALID] },
                onChange: e => onChange(e),
              })}
            />
            {errors['emailID'] && <span className="form__field__error">{errors['emailID'].message}</span>}
          </div>
        </div>
        <div className="recovery-password__actions wt-dialog__actions wt-dialog--end">
          <button
            type="submit"
            className="wt-btn wt-btn--circle wt-btn--theme"
          >
            <FormattedMessage id="login.recover_password.send"/>
          </button>
        </div>
      </form>
    </div>
  );
};

RecoveryPassword.defaultProps = {
  onRecovery: () => {},
  onChange: () => {},
};

RecoveryPassword.propTypes = {
  onRecovery: PropTypes.func,
  onChange: PropTypes.func,
};

export default RecoveryPassword;
