import React from 'react';
import PropTypes from 'prop-types';

import FooterLinks from '../molecules/footer-links.component';

const Footer = ({isSticky, customClass}) => {
  return (
    <div className={`footer-bar ${isSticky ? 'footer-bar--sticky' : ''} ${customClass}`}>
      <div className="footer-bar__container">
        {isSticky && (
          <div className="footer-bar__logo">
            <img className="footer-bar__logo" alt="" src="images/logo-plain.svg"/>
          </div>
        )}
        <div className="footer-bar__info">
          <span className="mx-3">weetrust&reg; 2024</span>
          <div className="footer-bar__links">
            <FooterLinks customLinkClass='mx-3 link-dark' />
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  isSticky: false,
  customClass: '',
};

Footer.propTypes = {
  isSticky: PropTypes.bool,
  customClass: PropTypes.string,
};

export default Footer;
