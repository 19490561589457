import {actionTypeConstant} from '../factories/constant';
import { sessionManager } from '../factories/sessionManager';

export const setUserTemplates = (payload) => {
  const action = {
    type: actionTypeConstant.SET_USER_TEMPLATES,
    payload: payload,
  };

  return (dispatch) => dispatch(action);
};

export const setDefaultTemplate = (payload) => {
  const action = {
    type: actionTypeConstant.SET_DEFAULT_TEMPLATES,
    payload: payload,
  };

  return (dispatch) => dispatch(action);
};

export const removeTemplate = (payload) => {
  const action = {
    type: actionTypeConstant.REMOVE_TEMPLATE,
    payload: payload,
  };

  return (dispatch) => dispatch(action);
};

export const setCurrentTemplateConfig = (payload) => {
  const action = {
    type: actionTypeConstant.SET_CURRENT_TEMPLATE_CONFIG,
    payload: payload,
  };
  sessionManager.setDataInLocalStorage(payload, 'currentTemplateConfig');
  return (dispatch) => dispatch(action);
};

export const setVarsTemplateDocument= (payload) => {
  const action = {
    type: actionTypeConstant.SET_VARS_TEMPLATE_DOCUMENT,
    payload: payload,
  };
  sessionManager.setDataInLocalStorage(payload, 'varsTemplateDocument');
  return (dispatch) => dispatch(action);
};

export const setTemplateMassiveFiles = (payload) => {
  const action = {
    type: actionTypeConstant.SET_TEMPLATE_MASSIVE_FILES,
    payload: payload,
  };

  return (dispatch) => dispatch(action);
};
