import { fetchInvoice } from '../services/invoice.service';

export const getInvoices = () => (dispatch) => {
  dispatch({
    type: 'FETCH_INVOICE_REQUEST',
  });
  fetchInvoice().then(
    (invoices) => dispatch({
      type: 'FETCH_INVOICE_SUCCESS',
      payload: {
        invoices,
      },
    })
  )
    .catch(() => dispatch({
      type: 'FETCH_INVOICE_FAILED',
    }));
};
