import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const MembershipCard = ({hasPromo, baseMembership}) => {
  return (
    <div className="membership-card membership-card--center">
      <div className="membership-card__header"><FormattedMessage id="membership.card.title" /></div>
      <div className="membership-card__container">
        
        <div className="membership-card__info">
          <div className="membership-card__graphic"><img src="/images/logo-with-shadow.svg" alt="Membresía Información" /></div>
          <div  className="membership-card__title"><FormattedMessage id="membership.card.subtitle" /></div>
        </div>
        <div className="membership-card__subtitle"><FormattedMessage id="membership.card.price.title" /> <strong><span>mxn${baseMembership && baseMembership.amount ? baseMembership.amount: ''}</span></strong> <FormattedMessage id="membership.card.price.period" /></div>
        {hasPromo && (
          <div className="membership-card__caption">
            <div className="membership-card__caption__title">
              <FormattedMessage id="membership.card.promo.caption" />
            </div>
          </div>
        )}
        <div className="membership-card__separator"></div>
      </div>
    </div>
  );
};

MembershipCard.defaultProps = {
  hasPromo: false,
};

MembershipCard.propTypes = {
  hasPromo: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  baseMembership: state.products && state.products.baseMembership ? state.products.baseMembership : null,
});

export default connect(mapStateToProps)(MembershipCard);
