import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setStorageOnboardingTarget, findStorageOnboardingTarget } from '../utils/onboarding.util';
import { setOnboardingTarget } from '../actions';
import OnboardingTourCards from '../components/organisms/onboarding-tour.component';

const OnboardingTourContainer = ({ onboarding, user, notification, dialog, setOnboardingTarget }) => {

  useEffect(() => {
    if (onboarding.tourList.length > 0) {
      validateNextTarget();
    }
  }, [onboarding.tourList]);

  const handleNextTour = () => {
    if (!isDialogShown()) {
      const userTour = user && user.user && user.user.userID ? user.user.userID : 'invitado';
      if (onboarding.target && !findStorageOnboardingTarget(userTour, onboarding.target)) {
        setStorageOnboardingTarget(userTour, onboarding.target);
        validateNextTarget();
      }
    }
  };

  const validateNextTarget = () => {
    if (!isDialogShown()) {
      if (onboarding.tourList) {
        const userTour = user && user.user && user.user.userID ? user.user.userID: 'invitado';
        const pendingTargetTour = onboarding.tourList.filter(item => !findStorageOnboardingTarget(userTour, item));
        if (pendingTargetTour && pendingTargetTour.length > 0) {
          setOnboardingTarget({target: pendingTargetTour[0]});
        } else {
          setOnboardingTarget({target: ''});
        }
      } else {
        setOnboardingTarget({target: ''});
      }
    }
  };

  const isDialogShown = () => {
    return (notification.isShown || dialog.isShown);
  };

  return (
    <>
      {!isDialogShown() && (
        <OnboardingTourCards target={onboarding.target} targetProps={onboarding.targetProps} onNextTarget={handleNextTour} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  onboarding: state.onboarding,
  user : state.user ? state.user : null,
  notification: state.notification,
  dialog: state.dialog,
});

export default connect(mapStateToProps, {setOnboardingTarget})(OnboardingTourContainer);
