import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BillingService } from '../../../services/billing.service';
import { stripeTransactionType } from '../../../factories/constant';

const PaymentMethods = ({currentMethod, onChange}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const newCardValue = stripeTransactionType.ADD_NEW_CARD;

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = async() => {
    const stripeUserMethods = await BillingService.getPaymentMethods();
    if (stripeUserMethods) {
      setPaymentMethods(stripeUserMethods);
      if (stripeUserMethods.length === 0 ) {
        handleChangeMethod(newCardValue);
      }
    } else {
      handleChangeMethod(newCardValue);
    }
  };

  const handleChangeMethod = (value) => {
    onChange(value);
  };

  return (
    <>
      {paymentMethods && paymentMethods.length > 0 && (
        <div className="payment-methods">
          <div className="payment-methods__title title-section-bar">
            <FormattedMessage id="payment.card.methods" />
          </div>
          <div className="payment-methods__container">
            {paymentMethods.map((method, i) => (
              <div className="payment-methods__item" key={`method-${i}`}>
                <div className="form__field__list--radio">
                  <input className="form__field__list__input"
                    id={`payment-method-${i}`}
                    type="radio"
                    name="payment-methods"
                    value={method.paymentMethodId}
                    checked={currentMethod === method.paymentMethodId}
                    onChange={() => handleChangeMethod(method.paymentMethodId)}
                  />
                  <label className="form__field__list__label" htmlFor={`payment-method-${i}`}>
                    {method.brand} ****{method.last4} 
                  </label>
                </div>
              </div>
            ))}
            <div className="payment-methods__item payment-methods__item--full">
              <div className="form__field__list--radio">
                <input className="form__field__list__input"
                  id="payment-method-new"
                  type="radio"
                  name="payment-methods"
                  value={newCardValue}
                  checked={currentMethod === newCardValue}
                  onChange={() => handleChangeMethod(newCardValue)}
                />
                <label className="form__field__list__label" htmlFor="payment-method-new">
                  <FormattedMessage id="payment.card.new.option" />
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    
  );
};

PaymentMethods.defaultProps = {
  currentMethod: '',
  onChange: () => {},
};

PaymentMethods.propTypes = {
  currentMethod: PropTypes.string,
  onChange: PropTypes.func,
};

export default PaymentMethods;