import User from '../models/userModel';
import {genericConstants, screenPathConstants} from '../constant';

import {sessionManager} from '../sessionManager';
import {history} from '../../utils/history';
import userService from '../../services/user.service';
import AuthService from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { LocalStorageRepository } from '../../repositories/local-storage.repository';


class Firebase {
  _login = async (user) => {
    if (!user || !Object.keys(user).length) {
      return Promise.reject();
    }

    const userAuth = await AuthService.getAccessToken(user.emailID, user.password);

    if (!userAuth) {
      return Promise.reject({ code: genericConstants.FIREBASE_ERROR_CODE.GENERIC_ERROR });
    }

    if (!userAuth.token) {
      return Promise.reject({ code: genericConstants.FIREBASE_ERROR_CODE.WRONG_CREDENTIALS });
    }

    if (!userAuth.emailVerified) {
      return Promise.reject({code: genericConstants.FIREBASE_ERROR_CODE.EMAIL_NOT_VERIFIED});
    }

    const userProfile = await this.getUserProfile(userAuth.token, userAuth.refreshToken, userAuth.sessionToken);

    if (!userProfile) {
      return Promise.reject({ message: 'Issue resolve to profile information' });
    }

    return userProfile;
  };

  _loginWithToken = async (token) => {
    try {
      if (!token || !token.accessToken || !token.refreshToken) {
        return false;
      }
      const userProfile = await this.getUserProfile(token.accessToken, token.refreshToken, token.sessionToken);
      if (!userProfile) {
        throw new Error('Issue resolve to profile information');
      }
      return userProfile;
    } catch (e) {
      console.log('Login With Token Error', e);
      return null;
    }
  };

  _logOut = async () => {
    LocalStorageRepository.cleanToken();
    LocalStorageRepository.cleanRefreshAccessToken();
    LocalStorageRepository.cleanSessionToken();
    return Promise.resolve(true);
  };

  _createUserWithEmailAndPassword = async (user) => {
    const newUser = new User(user);

    const response = await ProfileService.createNewUser({
      email: newUser.emailID,
      password: user.password,
      name: newUser.firstName,
      lastName: newUser.lastName,
      secondName: newUser.secondName,
      phone: newUser.phone,
    });

    if (!response) {
      return Promise.reject({ code: genericConstants.FIREBASE_ERROR_CODE.ALREADY_EXISTS });
    }

    userService.userSignUp({
      email: newUser.emailID,
      name: newUser.firstName,
      lastName: newUser.lastName,
      secondName: newUser.secondName,
      phone: newUser.phone,
    });

    return newUser;
  };

  _createSubUserWithEmailAndPassword = async (user) => {
    const newUser = new User(user);

    const response = await ProfileService.createNewSubUser({
      email: newUser.emailID,
      password: user.password,
      name: newUser.fullName,
    });

    if (!response || response.statusCode) {
      return Promise.reject({ code: genericConstants.FIREBASE_ERROR_CODE.ALREADY_EXISTS, message: response.message });
    }

    userService.userSignUp({
      email: newUser.emailID,
      name: newUser.fullName,
    });

    return newUser;
  };

  _sendResetPasswordMails = (email) => {
    if (!email) {
      return false;
    }

    return AuthService.sendRecoveryPassword({ email });
  };

  _resendVerificationEmail = async (email) => {
    if (!email) {
      return false;
    }

    return await AuthService.sendVerifyEmail({ email });
  };

  //FIXME: todo
  _changePasswordForSubUser = async () => {

  };

  _getUserFromUserID = async () => {
    return await ProfileService.getInfo();
  };

  _getSubUser = async () => {
    return await ProfileService.getSubUsers();
  };

  _getSubUserFromUserID = async (userId) => {
    return await ProfileService.getSubUsersFromUserId(userId);
  };

  //FIXME: todo
  _authenticateCurrentUser = async (user, emailID, password) => {

  };

  _updatePassword = async (emailID, lastPassword, password) => {
    return await ProfileService.changePassword({ lastPassword, password });
  };

  /* TODO: Pass to profile - DONE */
  _updateProfileDetails = async (userID, updateDataObject) => {
    delete updateDataObject.subscription;

    await ProfileService.updateAll(updateDataObject);

    return Promise.resolve();

  };

  _updateSubscriptionData = async (userID, updateDataObject, isChangedSubscription = true) => {
    let data = updateDataObject;

    if (isChangedSubscription) {
      let firebaseSubscriptionData = await this.getGlobalSubscription().catch((err) => {
        return Promise.reject(err);
      });

      if (firebaseSubscriptionData && Object.keys(firebaseSubscriptionData).length) {
        data = firebaseSubscriptionData;
        data.numberOfDocumentsUploaded = updateDataObject.numberOfDocumentsUploaded;
        data.numberOfUserAdded = updateDataObject.numberOfUserAdded;
        data.bioCreditsFaceID = updateDataObject.bioCreditsFaceID || data.bioCreditsFaceID;
        data.backgroundCheckCredits = updateDataObject.backgroundCheckCredits || data.backgroundCheckCredits;
        data.identityCreditsIncodeP1 = updateDataObject.identityCreditsIncodeP1 || data.identityCreditsIncodeP1;
        data.identityCreditsIncodeP2 = updateDataObject.identityCreditsIncodeP2 || data.identityCreditsIncodeP2;
        data.identityCreditsIncodeP3 = updateDataObject.identityCreditsIncodeP3 || data.identityCreditsIncodeP3;
        data.userID = updateDataObject.userID;
        data.isSignatureText = updateDataObject.isSignatureText || data.isSignatureText;
        data.whatsappConsentAccepted = updateDataObject.whatsappConsentAccepted || false;
        data.attachmentConsentAccepted = updateDataObject.attachmentConsentAccepted || false;
      }
    }

    return new Promise((resolve, reject) => {
      ProfileService.updateAllSubscription(data)
        .then(function () {
          return resolve();
        })
        .catch(function (error) {
          return reject(error);
        });
    });
  };

  _getSubscriptionFromUserID = async () => {
    return await this.getGlobalSubscription();
  };

  _getSubUserSubscriptionFromUserID = async () => {
    return await this.getGlobalSubscription();
  }

  _handleVerifyEmail = async (actionCode, email) => {
    return await AuthService.verifyEmail(actionCode, email);
  };

  _checkActionCode = async (actionCode) => {
  };

  _resetPasswordResetCode = async (actionCode, payload) => {
    return await AuthService.recoveryPassword(actionCode, payload);
  };

  _verifyUpdatePassword = async (body) => {
    return await AuthService.verifyUpdatePassword(body);
  };

  _updateVerifiedUserPassword = async (email, body) => {
    return await AuthService.updateVerifiedUserPassword(email, body);
  };

  _validateSession = async (showToust = true) => {
    // TODO: Cookie
    const user = sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.USER);
    if (user === undefined || !user || !Object.keys(user)) {
      this._handleSessionValidation(showToust);
      return false;
    }
    const userDetails = await this._getUserFromUserID();
    if (!userDetails || !Object.keys(userDetails)) {
      this._handleSessionValidation(showToust);
      return false;
    }
    const localSessionToken = user.sessionToken ? user.sessionToken : '';
    const firebaseSessionToken = userDetails.sessionToken ? userDetails.sessionToken : '';

    const allowedUserIDs = [
      'Sj4hFrKrIogJnooNR7bTZGYYCAH2',
      'F2KscBjW6adZw81uoa7Ncr4Bgen2',
      'sHmkQGCxjQc3hgmu5kMWRXlUKxn1',
      '57sJE5HQJUgsuIXtTletMqIxH593',
      'Ydb2XN2QgCPNIR9tW5OASGQzhVv2',
      'fizuZdfdCZTcYmhpPstfGsfQTfV2',
      'qhh9zBj0qTR8KVdRxzGDWTgs0n22',
      'fyBocQRpWvg40btbthlvIEDaCMo1',
      'qDL3Ln7mqTQHOcFU2IZNm0SpSdE3'
    ];
    
    if (userDetails && allowedUserIDs.includes(userDetails.userID)) {
      return true;
    }

    if (!firebaseSessionToken || !localSessionToken || firebaseSessionToken !== localSessionToken) {
      this._handleSessionValidation(showToust);
      return false;
    }

    if (user.redirectUrl) {
      window.location.replace(`${user.redirectUrl}/access/auth/redirect?u=${user.userID}&t=${LocalStorageRepository.getAccessToken()}&r=${LocalStorageRepository.getRefreshAccessToken()}&st=${user.sessionToken}`);
    }

    return true;
  };

  _handleSessionValidation = (showToust) => {
    // TODO: Cookie
    sessionManager.removeDataFromCookies(genericConstants.COOKIES_KEY.USER);
    sessionManager.removeDataFromLocalStorage(genericConstants.COOKIES_KEY.DOCUMENT);
    if (showToust) {
      localStorage.setItem('is-expired-token', 'expired');
      history.replace({pathname: screenPathConstants.LOGIN});
    }
  };

  removeSubUser = async (uid) => {
    return await ProfileService.deleteSubUser(uid);
  }

  /* FIXME: Pass to profile */
  _updateWhiteLabelSubUserFromUserID = async (updateDataObject) => {
    const subUser = await this._getSubUserFromUserID(updateDataObject.userID).catch(() => {});
    if (subUser) {
      const { whiteLabel, isWhiteLabel} = updateDataObject;
      subUser.map((user) => {
        let { subscription } = user;
        const userData = {
          ...user,
          subscription: {
            ...subscription,
            whiteLabel,
            isWhiteLabel,
          },
        };
        this._updateSubUser(userData);
      });
    }
  }

  /* FIXME: Pass to profile */
  _updateSubUser = async (updateDataObject) => {
    return new Promise((resolve, reject) => {
      ProfileService.updateSubUserWhiteLabel(updateDataObject)
        .then(function () {
          return resolve();
        })
        .catch(function (error) {
          return reject(error);
        });
    });
  };

  // FIXME: Send object instead token
  signInWithCustomToken = async (user) => {
    LocalStorageRepository.setAccessToken(user.token);

    const userDetails = await this._getUserFromUserID();

    if (userDetails.role !== genericConstants.USER_ROLE.SUB_USER && !user.emailVerified) {
      return Promise.reject({ code: genericConstants.FIREBASE_ERROR_CODE.EMAIL_NOT_VERIFIED });
    }

    const parsedUserData = new User(userDetails);
    parsedUserData.sessionToken = user.sessionToken;
    await this._updateProfileDetails(parsedUserData.userID, parsedUserData);

    const updatedUserDetails = await this._getUserFromUserID();
    return new User(updatedUserDetails);
  }

  getGlobalSubscription = async (userID) => {
    const userData = await this._getUserFromUserID();

    if (userData.role === genericConstants.USER_ROLE.SUB_USER) {
      return await ProfileService.getSubUserSubscription();
    } else {
      return await ProfileService.getSubscription();
    }
  }

  getUserProfile = async (token, refreshToken, sessionToken) => {
    LocalStorageRepository.setAccessToken(token);
    LocalStorageRepository.setRefreshAccessToken(refreshToken);

    await ProfileService.registerFirstLogin();
    const userProfile = await ProfileService.getInfo();

    if (!userProfile) {
      return false;
    }

    const userData = new User(userProfile);
    userData.sessionToken = sessionToken;

    const updatedUserData = await ProfileService.updateAll(userData);
    return new User(updatedUserData);
  }
}

export default new Firebase();
