import { actionTypeConstant, genericConstants } from '../factories/constant';
import { sessionManager } from '../factories/sessionManager';

export const signUpSuccess = (data) => {
  const payload = {
    ...data,
    subscription: {
      ...data.subscription,
    },
  };

  delete payload.subscription.whiteLabel;
  delete payload.subscription.changedSubscription;

  const action = {
    type: actionTypeConstant.SIGN_UP_SUCCESS,
    data: payload,
  };

  // TODO: Cookie
  sessionManager.setDataInCookies(payload, genericConstants.COOKIES_KEY.USER);

  return (dispatch) => dispatch(action);
};

export const websocketConnection = (hasConnection) => {
  const action = {
    type : actionTypeConstant.SOCKET_CONNECTION,
    data: { hasConnection },
  };
  return (dispatch) => dispatch(action);
};

export const setSocket = (socket) => {
  const action = {
    type : actionTypeConstant.SET_SOCKET,
    data: { socket },
  };
  return (dispatch) => dispatch(action);
};

export const loginSuccess = (data, bundleConfig = {}) => {
  const payload = parseUser(data, bundleConfig);

  const action = {
    type: actionTypeConstant.LOGIN_SUCCESS,
    data: payload,
  };

  // TODO: Cookie
  sessionManager.setDataInCookies(payload, genericConstants.COOKIES_KEY.USER);

  return (dispatch) => dispatch(action);
};

export const logoutSuccess = (data) => {
  const action = {
    type: actionTypeConstant.LOGOUT_SUCCESS,
    data,
  };

  return (dispatch) => dispatch(action);
};

export const updateUserSuccess = (data) => {
  const payload = parseUser(data);

  const action = {
    type: actionTypeConstant.UPDATE_SUCCESS,
    data: payload,
  };

  // TODO: Cookie
  sessionManager.setDataInCookies(payload, genericConstants.COOKIES_KEY.USER);

  return (dispatch) => dispatch(action);
};

export const setExpireToken = (isExpireToken) => {
  const action = {
    type: actionTypeConstant.SET_EXPIRE_TOKEN,
    data: isExpireToken,
  };

  return (dispatch) => dispatch(action);
};

export const setUserVerify = () => {
  const action = {
    type: actionTypeConstant.SET_USER_VERIFY,
  };

  return (dispatch) => dispatch(action);
};

export const setSubusers = (data) => {
  const action = {
    type: actionTypeConstant.SET_SUBUSERS,
    data: data,
  };
  return (dispatch) => dispatch(action);
};

export const setBillingSubscription = (data) => {
  const action = {
    type: actionTypeConstant.SET_BILLING_SUBSCRIPTION,
    data: data,
  };
  return (dispatch) => dispatch(action);
};

const parseUser = (user, bundleConfig = {}) => {
  return {
    ...bundleConfig,
    dob: user.dob,
    role: user.role,
    phone: user.phone,
    apiKey: user.apiKey,
    userID: user.userID,
    isAdmin: user.isAdmin,
    emailID: user.emailID,
    addedBy: user.addedBy,
    fullName: user.fullName,
    industry: user.industry,
    activity: user.activity,
    signature: user.signature,
    isNewUser: user.isNewUser,
    companyName: user.companyName,
    sessionToken: user.sessionToken,
    isFreeTrailUsed: user.isFreeTrailUsed,
    firstLoginDate: user.firstLoginDate,
    openPayCustomerID: user.openPayCustomerID,
    hasRegisteredCard: user.hasRegisteredCard,
    permissionsDenied: user.permissionsDenied,
    subscription: {
      planName: user.subscription.planName,
      expiryDate: user.subscription.expiryDate,
      isSubscribed: user.subscription.isSubscribed,
      isActivateID: user.subscription.isActivateID,
      documentLimit: user.subscription.documentLimit,
      purchasedDate: user.subscription.purchasedDate,
      isActivateFaceID: user.subscription.isActivateFaceID,
      bioCreditsFaceID: user.subscription.bioCreditsFaceID,
      isGeolocationActivate: user.subscription.isGeolocationActivate,
      isMassiveSignature: user.subscription.isMassiveSignature,
      isEndorsementMassiveSignature: user.subscription.isEndorsementMassiveSignature,
      isFormat: user.subscription.isFormat,
      backgroundCheckCredits: user.subscription.backgroundCheckCredits,
      numberOfDocumentsUploaded: user.subscription.numberOfDocumentsUploaded,
      numberOfDocumentsCompleted: user.subscription.numberOfDocumentsCompleted,
      isActivateBackgroundCheckValidation: user.subscription.isActivateBackgroundCheckValidation,
      identityProvider: user.subscription.identityProvider,
      identityCreditsIncodeP1: user.subscription.identityCreditsIncodeP1,
      identityCreditsIncodeP2: user.subscription.identityCreditsIncodeP2,
      identityCreditsIncodeP3: user.subscription.identityCreditsIncodeP3,
      identityCreditsIncodeP1Completed: user.subscription.identityCreditsIncodeP1Completed,
      identityCreditsIncodeP2Completed: user.subscription.identityCreditsIncodeP2Completed,
      identityCreditsIncodeP3Completed: user.subscription.identityCreditsIncodeP3Completed,
      identityCreditsVeridocOcrCompleted: user.subscription.identityCreditsVeridocOcrCompleted,
      identityCreditsVeridocFaceIdCompleted: user.subscription.identityCreditsVeridocFaceIdCompleted,
      identityCreditsVeridocBackgroundCheckCompleted: user.subscription.identityCreditsVeridocBackgroundCheckCompleted,
      isSignatureText: user.subscription.isSignatureText,
      methodOfBilling: user.subscription.methodOfBilling,
      userLimit: user.subscription.userLimit,
      defaultCountry: user.subscription.defaultCountry,
      usage: {
        documentsCompleted: user.subscription.usage.documentsCompleted,
        packA: user.subscription.usage.packA,
        packB: user.subscription.usage.packB,
        packC: user.subscription.usage.packC,
      },
      maxTemplates: parseInt(user.subscription.maxTemplates) || 10,
      autocaptureSDKEnabled: user.subscription.autocaptureSDKEnabled,
      isPassiveLiveness: user.subscription.isPassiveLiveness || false,
      whatsappCredits: parseInt(user.subscription.whatsappCredits || 0),
      hideDeleteDocumentSubUser: user.subscription.hideDeleteDocumentSubUser || false,
      whatsappConsentAccepted: user.subscription.whatsappConsentAccepted || false,
      attachmentConsentAccepted: user.subscription.attachmentConsentAccepted || false,
      isActiveAttachments: user.subscription.isActiveAttachments,
      isRatification: user.subscription.isRatification || false,
      isEndorsementSpecial: user.subscription.isEndorsementSpecial || false,
      staticPositionVersion: user.subscription.staticPositionVersion || '',
      isDisableDrift: user.subscription.isDisableDrift || false,
      enableAlivePro: user.subscription.enableAlivePro || false,
      isAutoSign: user.subscription.isAutoSign || false,
      isAutoSignDefault: user.subscription.isAutoSignDefault || false,
    },
  };
};
