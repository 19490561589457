import { billingService } from '../factories/constant';
import { HttpInterceptorService } from './http-interceptor.service';
import { LocalStorageRepository } from '../repositories/local-storage.repository';

const baseUrl = process.env.REACT_APP_BILLING_SERVICE_URL;
const isNotHttpCodeSuccess = (payload) => payload.statusCode && payload.statusCode !== 200;

export class BillingService {
  static async getPaymentMethods() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.GET_PAYMENT_METHODS}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_PAYMENT_METHOD] error: ', e);
      return null;
    }
  }

  static async getProduct(productId) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.GET_PRODUCT}/${productId}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_PRODUCT] error: ', e);
      return null;
    }
  }

  static async getProductByName(productName) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.GET_PRODUCTS}/${productName}/detail`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_PRODUCT_BY_NAME] error: ', e);
      return null;
    }
  }

  static async enableDynamicMembership() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };
        const response = await fetch(`${baseUrl}${billingService.SUBSCRIPTIONS}/dynamic/start`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[ENABLE_DYNAMIC_MEMBERSHIP_NOW] error: ', e);
      return null;
    }
  }

  static async getMethodDefault() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.METHOD_DEFAULT}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_METHOD_DEFAULT] error: ', e);
      return null;
    }
  }

  static async getSuscriptionUser() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.GET_SUSCRIPTION_USER}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GET_SUSCRIPTION_USER] error: ', e);
      return null;
    }
  }

  static async setMethodDefault(paymentMethodId) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify({ paymentMethodId }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.METHOD_DEFAULT}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[UPDATE_METHOD_DEFAULT] error: ', e);
      return null;
    }
  }

  static async removeMethod(methodId) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.METHOD_DEFAULT}/${methodId}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[REMOVE_METHOD] error: ', e);
      return null;
    }
  }

  static async payProducts(data) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.PAY_PRODUCTS}`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[GENERATE_PAYMENT_INTENT_PAY_PRODUCTS] error: ', e);
      return null;
    }
  }

  static async addPaymentMethodToCustomer(paymentMethodId) {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.PAYMENT_METHOD}/${paymentMethodId}/attach`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[addPaymentMethodToCustomer] error: ', e);
      return null;
    }
  }

  static async redeemSubscriptionPromo() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.SUBSCRIPTIONS}/promos/redeem`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[redeemSubscriptionPromo] error: ', e);
      return null;
    }
  }

  static async startSubscriptionMembership() {
    try {
      const payload = await HttpInterceptorService.run(async () => {
        const config = {
          method: 'PUT',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
          },
        };

        const response = await fetch(`${baseUrl}${billingService.SUBSCRIPTIONS}/base/start`, config);
        return await response.json();
      });

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[startSubscriptionMembership] error: ', e);
      return null;
    }
  }
}
