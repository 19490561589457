import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const ReminderExpirationNotice = ({user, baseMembership}) => {
  const [timeHours, setTimeHours] = useState(0);

  useEffect(() => {
    const userData = user && user.user ? user.user : null;
    if (userData && userData.subscription) {
      const expirePlanDate = userData.subscription.expiryDate ? userData.subscription.expiryDate : null;
      if (expirePlanDate) {
        const currentDate = moment();
        const dateTimeEnding = moment(expirePlanDate, 'YYYY-MM-DD').endOf('day');
        const diffDate = dateTimeEnding.diff(currentDate);
        setTimeHours(moment.duration(diffDate).hours());
      }
    }
  }, [user]);

  return (
    <div className="plans-notice">
      <div className="plans-notice__introduction">
        <div className="plans-notice__title">
          <span className="plans-notice__title--large"><FormattedMessage id="freeplan.payment.notice.title.reminder" /></span>
        </div>
        <div className="plans-notice__subtitle">
          <span className="plans-notice__subtitle--large"><FormattedMessage id="freeplan.payment.notice.subtitle.reminder" /></span>
        </div>
      </div>
      <div className="plans-notice__content">
        <div className="plans-notice__graphic"><img src="/images/timing-clock.svg" alt="Free Plan tiempo restante" /></div>
      </div>
      <div className="plans-notice__time-counter">
        <FormattedMessage id="freeplan.payment.notice.timer.fragment_1" />
        {timeHours}
        <FormattedMessage id="freeplan.payment.notice.timer.fragment_2" />
        <strong>
          <FormattedMessage id="freeplan.payment.notice.timer.fragment_3" />
        </strong>
      </div>
      <div className="plans-notice__bottom">
        <div className="plans-notice__suscription plans-notice__suscription--large">
          <FormattedMessage id="freeplan.payment.notice.suscription.reminder" values={{productPrice: baseMembership && baseMembership.amount ? baseMembership.amount: ''}} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  baseMembership: state.products && state.products.baseMembership ? state.products.baseMembership : null,
});

export default connect(mapStateToProps)(ReminderExpirationNotice);
