import React from 'react';
import PropTypes from 'prop-types';

import { genericConstants } from '../factories/constant';
import ReminderExpirationNotice from '../components/molecules/notifications/reminder-expiration-notice.component';
import SuscriptionNowNotice from '../components/molecules/notifications/suscription-now-notice.component';
import PlanExpiredNotice from '../components/molecules/notifications/plan-expired-notice.component';
import DocumentsExpiredNotice from '../components/molecules/notifications/documents-expired-notice.component';
import PlanPromoNotice from '../components/molecules/notifications/plan-promo-notice.component';
import SubscriptionProblemNotice from '../components/molecules/notifications/subscription-problem-notice.component';
import DinamicMembershipNotice from '../components/molecules/notifications/dynamic-membership.notice.component';
import EnableSuscriptionNotice from '../components/molecules/notifications/enable-suscription.notice.component';
import UpdatePaymentMethodsNotice from '../components/molecules/notifications/update-payment-methods-notice.component';

const PaymentNoticeContainer = ({type}) => {
  const renderNotice = () => {
    switch(type) {
      case genericConstants.NOTIFICATION_OPERATION.EXPIRATION_FREE_PLAN_REMINDER:
        return (<ReminderExpirationNotice />);
      case genericConstants.NOTIFICATION_OPERATION.SUBSCRIPTION_NOW:
        return (<SuscriptionNowNotice />);
      case genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_FREEPLAN_EXPIRED:
        return (<PlanExpiredNotice />);
      case genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DOCUMENT_LIMIT_EXPIRED:
        return (<DocumentsExpiredNotice />);
      case genericConstants.NOTIFICATION_OPERATION.PAYMENT_BY_DEBTOR_PLAN:
        return (<SubscriptionProblemNotice />);
      case genericConstants.NOTIFICATION_OPERATION.PROMO_PLAN_PAYMENT:
        return (<PlanPromoNotice />);
      case genericConstants.NOTIFICATION_OPERATION.ENABLE_SUSCRIPTION_NOW:
        return (<EnableSuscriptionNotice />);
      case genericConstants.NOTIFICATION_OPERATION.OPEN_KEY_ACTIVATION:
        return (<DinamicMembershipNotice />);
      case genericConstants.NOTIFICATION_OPERATION.UPDATE_PAYMENT_METHODS:
        return (<UpdatePaymentMethodsNotice />);
      default:
        return (<></>);
    }
  };
  return (
    <>
      {renderNotice()}
    </>
  );
};

PaymentNoticeContainer.defaultProps = {
  type: '',
};

PaymentNoticeContainer.propTypes = {
  type: PropTypes.string,
};

export default PaymentNoticeContainer;
