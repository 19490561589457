import {SET_FORMAT} from '../actions';

const initialState = {
  defaultPreConfigName: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_FORMAT:
    return {
      ...state,
      defaultPreConfigName: action.payload.defaultPreConfigName,
    };
  default:
    return state;
  }
}
