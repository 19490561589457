import { actionTypeConstant } from '../factories/constant';

const emptyToast = {
  data: {},
};

const initialState = emptyToast;

export default function(state = initialState, action) {
  switch(action.type) {
    case actionTypeConstant.SET_TOAST: {
      return {
        data: action.payload,
      }
    }
    default: {
      return state;
    }
  }
}
