import React from 'react';

import { SpotlightManager, SpotlightTransition } from '@atlaskit/onboarding';
import SpotlightContainer from '../containers/onboarding-tour.container';

const OnboardingTour = ({ children }) => {
  return (
    <SpotlightManager>
      {children}
      <SpotlightTransition>
        <SpotlightContainer />
      </SpotlightTransition>
    </SpotlightManager>
  );
};

export default OnboardingTour;
