const initialState = {
  invoices: [],
  message: '',
  isInvoiceLoader: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'FETCH_INVOICE_REQUEST': {
        return {
          ...state,
          invoices: [],
          isInvoiceLoader: true,
          message: '',
        }
    }
    case 'FETCH_INVOICE_SUCCESS': {
      return {
        ...state,
        invoices: action.payload.invoices,
        isInvoiceLoader: false,
        message: 'invoice fetch success',
      };
    }
    case 'FETCH_INVOICE_FAILED': {
      return {
        ...state,
        invoices: [],
        isInvoiceLoader: false,
        message: 'invoice fetch failed',
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
