import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "jquery/dist/jquery.min.js";
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import AppRoutes from './routes';
import store from './store.js';
import {localeSet} from "./actions";
import {Provider} from 'react-redux';
import {addLocaleData} from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import * as serviceWorker from './serviceWorker';
import { stopReportingRuntimeErrors } from 'react-error-overlay';
import * as Sentry from '@sentry/react';
import './style/custom.css';
import './sass/main.scss';
import { sentryError } from './factories/constant.js';

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors();
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cfd884ba6a4463b2f794f6bbfbc1017e@o307301.ingest.sentry.io/4506757020778496',
    integrations: [
      // Sentry.browserTracingIntegration(),
      // Sentry.replayCanvasIntegration(),
      // Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    ],
    beforeSend(event, hint) {
      const errorName = hint?.originalException?.name;
      const allowedErorNames = Object.values(sentryError);
      if (allowedErorNames.includes(errorName)) return event;
      return null;
    },
    // tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
  });
}

window.IDENTY_USER_SERVER = process.env.REACT_APP_IDENTY_USER_SERVER;
window.IDENTY_CLIENT_ID = process.env.REACT_APP_IDENTY_CLIENT_ID;
window.IDENTY_API_KEY = process.env.REACT_APP_IDENTY_API_KEY;

addLocaleData(en);
addLocaleData(es);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route component={AppRoutes}/>
    </Provider>
  </BrowserRouter>, document.getElementById('root'));
if (localStorage.englishLang) {
  store.dispatch(localeSet(localStorage.englishLang));
}
// store.dispatch(setLocale('es'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
