const authServiceUrl = process.env.REACT_APP_AUTH_SERVICE_URL;
const profileServiceUrl = process.env.REACT_APP_PROFILE_SERVICE_URL;

const isNotHttpCodeSuccess = (payload) => payload.statusCode && payload.statusCode !== 200;

class AuthService {
  static async getCustomAccessToken(token) {
    try {
      const body = { token };

      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${authServiceUrl}/nmp/access`, config);
      return await response.json();
    } catch (e) {
      return e;
    }
  }

  static async getAccessToken(email, password) {
    try {
      const body = { email, password };

      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${authServiceUrl}/auth/access`, config);
      return await response.json();
    } catch (e) {
      console.warn('Login error: ', e);
      return null;
    }
  }

  static async sendVerifyEmail(body) {
    try {
      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${profileServiceUrl}/profile/verify/email`, config);
      const payload = await response.json();

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[SEND_VERIFICATION_EMAIL] error: ', e);
      return null;
    }
  }

  static async verifyEmail(code, email) {
    try {
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email})
      };

      const response = await fetch(`${profileServiceUrl}/profile/verify/email/${code}`, config);
      const payload = await response.json();

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload.data;
    } catch (e) {
      console.warn('[VERIFICATION_EMAIL] error: ', e);
      return null;
    }
  }


  static async sendRecoveryPassword(body) {
    try {
      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${profileServiceUrl}/profile/verify/password`, config);
      const payload = await response.json();


      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[SEND_RECOVERY_PASSWORD] error: ', e);
      return null;
    }
  }

  static async recoveryPassword(code, body) {
    try {
      const config = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${profileServiceUrl}/profile/verify/password/${code}`, config);
      const payload = await response.json();

      if (isNotHttpCodeSuccess(payload)) {
        throw new Error(`Access code: ${payload.statusCode}`);
      }

      return payload;
    } catch (e) {
      console.warn('[RECOVERY_PASSWORD] error: ', e);
      return null;
    }
  }

  static async getRefreshToken(refreshToken) {
    try {
      const body = { token: refreshToken };

      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${authServiceUrl}/auth/refresh`, config);
      return await response.json();
    } catch (e) {
      console.warn('Refresh token error: ', e);
      return null;
    }
  }

  static async verifyUpdatePassword(body) {
    try {
      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${profileServiceUrl}/profile/verify/update-password`, config);
      return await response.json();
    } catch (e) {
      console.warn('Verify Update Password error: ', e);
      return null;
    }
  }

  static async updateVerifiedUserPassword(email, body) {
    try {
      const config = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(`${profileServiceUrl}/profile/verify/update-password/${email}`, config);
      return await response.json();
    } catch (e) {
      console.warn('Update Verified User Password error: ', e);
      return null;
    }
  }
}

export default AuthService;
