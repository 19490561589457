import { actionTypeConstant } from '../factories/constant';

const emptyDialog = {
  dialogId: '',
  isShown: false,
  type: '',
  data: {},
};

const initialState = emptyDialog;

export default function(state = initialState, action) {
  switch(action.type) {
    case actionTypeConstant.SHOW_CUSTOM_DIALOG: {
      return {
        ...action.payload,
        isShown: true,
      }
    }
    case actionTypeConstant.CLEAR_CUSTOM_DIALOG: {
      return emptyDialog;
    }
    default: {
      return state;
    }
  }
}
