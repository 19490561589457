import React from 'react';
import { FormattedMessage } from 'react-intl';

const SubscriptionProblemNotice = () => {
  
  const handleContact = () => {
    window.drift.api.openChat();
  };

  return (
    <div className="plans-notice plans-notice--problem">
      <div className="plans-notice__image">
        <img src="images/we-have-a-problem.svg" alt="Subscription problem" />
      </div>
      <div className="plans-notice__line">
        <img src="images/dialog-line.svg" alt="Subscription problem line" />
      </div>
      <div className="plans-notice__message">
        <FormattedMessage id="subscription.problem.notice.message" />
      </div>
      <div className="plans-notice__caption">
        <FormattedMessage id="subscription.problem.notice.caption.text1" />
        <a onClick={handleContact} className="link-theme"> <FormattedMessage id="subscription.problem.notice.caption.link" /> </a>
        <FormattedMessage id="subscription.problem.notice.caption.text2" />
      </div>
      <div className="plans-notice__action">
        <button
          onClick={handleContact}
          className="wt-btn wt-btn--circle wt-btn--theme"
        >
          <FormattedMessage id="subscription.problem.notice.button"/>
        </button>
      </div>
    </div>
  );
};

export default SubscriptionProblemNotice;
