import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import { screenPathConstants } from '../../factories/constant';

const FooterLinks = ({ customLinkClass}) => {
  const marketingUrl = process.env.REACT_APP_MARKETING_WEBSITE_URL;
  return (
    <>
      <a target="blank" className={customLinkClass} href={`${marketingUrl}${screenPathConstants.PRIVACY_POLICY}`}>
        <FormattedMessage id="menu.footer.privacy"/>
      </a>
      <a target="blank" className={customLinkClass} href={`${marketingUrl}${screenPathConstants.TERMS_AND_CONDITION}`}>
        <FormattedMessage id="menu.footer.terms"/>
      </a>
    </>
  );
};

FooterLinks.defaultProps = {
  customLinkClass: 'link-dark',
};

FooterLinks.propTypes = {
  customLinkClass: PropTypes.string,
};

export default FooterLinks;
