import 'whatwg-fetch';
import { httpConstants } from '../factories/constant';
import { HttpInterceptorService } from '../services/http-interceptor.service';

export const httpService = (method, headers, data, url) => {
  return HttpInterceptorService.run(async (newToken) => {
    const requestOptions = {
      method: method,
      headers: headers || {'Content-Type': 'application/json'},
    };

    if (newToken) {
      requestOptions.headers['Authorization'] = `Bearer ${newToken}`;
    }

    if (method !== httpConstants.METHOD_TYPE.GET) {
      requestOptions.body = JSON.stringify(data);
    }

    return fetch(url, requestOptions)
      .then(function handleResponse(response) {
        return response.text().then((text) => {
          const data = text && JSON.parse(text);
          return data;
        });
      }).catch(function (err) {
        return Promise.reject(err);
      });
  });
};

export const httpGetService = (url, headers, credentials = false) => {
  return HttpInterceptorService.run(async (newToken) => {
    const requestOptions = {
      method: httpConstants.METHOD_TYPE.GET,
      headers: headers || {'Content-Type': 'application/json'}
    };

    if (newToken) {
      requestOptions.headers['Authorization'] = `Bearer ${newToken}`;
    }

    if (credentials) {
      requestOptions['credentials'] = 'same-origin';
    }

    return fetch(url, requestOptions)
      .then(function handleResponse(response) {
        return response.text()
          .then((text) => {
            const data = text && JSON.parse(text);
            if (!data.success) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }

            return data;
          });
      });
  });
};

export const httpDownloadFile = async (url, fileName = 'document.pdf') => {
  return HttpInterceptorService.run(async () => {
    const response = await fetch(url).catch((err) => {
      return Promise.reject(err);
    });
    if (!response){
      return Promise.reject(false);
    }

    const data = await response.blob();
    const dataUrl = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
    const link = document.createElement('a');
    link.href = dataUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();
    return Promise.resolve(true);
  });
};

export const httpUploadFileService = (method, headers, data, url) => {
  return HttpInterceptorService.run(async (newToken) => {
    const options = {
      method: method,
    };

    options.body = data;

    if (headers && Object.keys(headers).length > 0) {
      options.headers = headers;
    }

    if (newToken) {
      options.headers['Authorization'] = `Bearer ${newToken}`;
    }

    return fetch(url, options)
      .then(function handleResponse(response) {
        return response.text().then(text => {
          const data = text && JSON.parse(text);
          return data;
        });
      }).catch(function (err) {
        return err;
      });
  });
};


export const httpCorporationDealService = (url) => {
  return HttpInterceptorService.run(async () => {
    const requestOptions = {
      method: httpConstants.METHOD_TYPE.GET,
    };
    return fetch(url, requestOptions)
      .then((response) => response)
      .catch(() => false);
  });
};

export const httpErrorHandle = (data) => {
  return HttpInterceptorService.run(async () => {
    const url = process.env.REACT_APP_ZAPIER_BASE_PATH_ERROR_HANDLE;

    if (data && data.err && data.err.responseCode === 400) {
      return;
    }

    const requestOptions = {
      method: httpConstants.METHOD_TYPE.POST,
      body: JSON.stringify(data)
    };

    return fetch(url, requestOptions)
      .then((response) => {
        return response.text().then(text => {
          const data = text && JSON.parse(text);

          return data;
        });
      })
      .catch(() => false);
  });
};

export const httpDownloadFileFromApi = async (url, fileName = 'file.pdf', type) => {
  return HttpInterceptorService.run(async () => {
    const response = await fetch(url).catch((err) => {
      return Promise.reject(err);
    });

    if (!response){
      return Promise.reject(false);
    }

    const data = await response.blob();
    const dataUrl = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
    const link = document.createElement('a');
    link.href = dataUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();
    return Promise.resolve(true);
  });
};

export const httpServiceExportExcel = async (method, headers, data, url) => {
  return HttpInterceptorService.run(async (newToken) => {
    const requestOptions = {
      method: method,
      headers: headers || {'Content-Type': 'application/json'},
    };

    if (newToken) {
      requestOptions.headers['Authorization'] = `Bearer ${newToken}`;
    }

    if (method !== httpConstants.METHOD_TYPE.GET) {
      requestOptions.body = JSON.stringify(data);
    }

    const response = await fetch(url, requestOptions).catch((err) => {
      return Promise.reject(err);
    });
    if (!response) {
      return Promise.reject(false);
    }

    const blob = await response.blob();
    const dataUrl = window.URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
    const link = document.createElement('a');
    link.href = dataUrl;
    link.setAttribute('download', `exportData-${new Date().getTime()}.xlsx`);
    document.body.appendChild(link);

    link.click();
    return Promise.resolve(true);
  });
};
