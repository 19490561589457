import path from 'path';
import _ from 'lodash';
import querystring from 'query-string';
import UserModel from '../factories/models/userModel';
import DocumentModel from '../factories/models/document';
import SubscriptionModel from '../factories/models/subscriptionModel';
import { LocalStorageRepository } from '../repositories/local-storage.repository';

import firebase from '../factories/firebase';
import { httpConstants, genericConstants } from '../factories/constant';
import { getSignedURL } from '../factories/AWSService';
import {
  httpService,
  httpGetService,
  httpUploadFileService,
  httpErrorHandle,
  httpServiceExportExcel,
} from '../factories/httpService';
import { sessionManager } from '../factories/sessionManager';
import { lhtLogs } from '../utils';
import { HttpInterceptorService } from './http-interceptor.service';

const serviceURL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

const isNotHttpCodeSuccess = (payload) => payload.responseCode && payload.responseCode !== 200;

async function generateApiKey(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.GENERATE_API_KEY}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject();
            }
            return new UserModel(response.responseData);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function getCountPendingDocuments(requestData) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.COUNT_PENDING_DOCUMENTS}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = {
    'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON,
    'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}`,
  };

  return httpService(method, header, requestData, url).then((response) => {
    if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
      return Promise.reject(response);
    }
    return Promise.resolve(response.responseData.pendingDocumentsCount);
  }).catch((err) => {
    return Promise.reject(err);
  });
}

async function searchFileName(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.SEARCH_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return {
                ...response.responseData,
                documents: response.responseData.documents ? (
                    response.responseData.documents.map((item) => new DocumentModel(item))
                ) : ([]),
            };
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}


async function pendingDocuments(requestData, page, sort) {
  await firebase._validateSession();
  const sorted = sort ? `&criteria=${sort.criteria}&order=${sort.order}` : '';
  const url = `${serviceURL}${httpConstants.API_END_POINT.PENDING_DOCUMENTS}?page=${page}${sorted}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

  return httpService(method, header, requestData, url)
    .then((response) => {
      if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
        return Promise.reject(response);
      }
      return {
        ...response.responseData,
        documents: response.responseData.documents ? (
          response.responseData.documents.map((item) => new DocumentModel(item))
        ) : ([]),
      };
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function advancedSearch(requestData, page, sort) {
    await firebase._validateSession();
    const sorted = sort ? `&criteria=${sort.criteria}&order=${sort.order}` : '';
    const url = `${serviceURL}${httpConstants.API_END_POINT.ADVANCED_SEARCH}?page=${page}${sorted}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return {
                ...response.responseData,
                documents: response.responseData.documents ? (
                    response.responseData.documents.map((item) => new DocumentModel(item))
                ) : ([]),
            };
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function advancedSearchEmails(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.ADVANCED_SEARCH_EMAILS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function advancedSearchExportExcel(dataUrl) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.ADVANCED_SEARCH_EXPORT_EXCEL}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpServiceExportExcel(method, header, dataUrl, url)
        .catch((err) => {
            console.error('error advancedSearchExportExcel => ', err)
            httpErrorHandle({
                url,
                dataUrl,
                err: err && err.message ? err.message : err,
            });
            return Promise.reject(err);
        });
}

async function advancedSearchBulkDownloadDocuments(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.ADVANCED_SEARCH_BULK_DOWNLOAD_DOCUMENTS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

function getDocumentByDocumentIDSignatory(documentID, signatoryID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.DOCUMENT_DOCUMENT_ID}/${documentID}/${signatoryID}`;
    const method = httpConstants.METHOD_TYPE.GET;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, documentID, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return false;
            }
            let whiteLabel = response.responseData.whiteLabel;
            let dataResponse = {
                whiteLabel,
                ...new DocumentModel(response.responseData, response.responseData),
            }
            return dataResponse;
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                documentID,
                signatoryID,
                err: err && err.message ? err.message : err,
            });
            return Promise.reject(err);
        });
}

function getDocumentBYID(documentID, queryString = {}) {

    const url = `${serviceURL}${httpConstants.API_END_POINT.GET_DOCUMENT}/${documentID}${_.isEmpty(queryString) ? '' : '?' + querystring.stringify(queryString)}`;
    const method = httpConstants.METHOD_TYPE.GET;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, '', url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                documentID,
                err: err && err.message ? err.message : err,
            });
            return Promise.reject(err);
        });
}

function getDocumentBySharedWithID(documentID,sharedWithID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.GET_DOCUMENT_SHARED}/${documentID}/${sharedWithID}`;
    const method = httpConstants.METHOD_TYPE.GET;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, '', url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                documentID,
                err: err && err.message ? err.message : err,
            });
            return Promise.reject(err);
        });
}

function getDocument(requestData, userID, page, sort) {
    const sorted = sort ? `&criteria=${sort.criteria}&order=${sort.order}` : '';
    const url = `${serviceURL}${httpConstants.API_END_POINT.DOCUMENT}/${userID}?page=${page}${sorted}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return {
                ...response.responseData,
                documents: response.responseData.documents ? (
                    response.responseData.documents.map((item) => new DocumentModel(item))
                ) : ([]),
            };
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                userID,
                err,
            });
            return Promise.reject();
        });
}

async function getSharedDocuments(requestData, userID, page, sort) {
    const sorted = sort ? `&criteria=${sort.criteria}&order=${sort.order}` : '';
    const url = `${serviceURL}${httpConstants.API_END_POINT.SHARED_DOCUMENT}/${userID}?page=${page}${sorted}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData,url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return {
                ...response.responseData,
                documents:  response.responseData.documents ? (
                    response.responseData.documents.map((item) => new DocumentModel(item))
                ) : ([]),
            };
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err,
            });
            return Promise.reject();
        });
};


async function deleteDocument(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.DELETE_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return response;
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject();
        });
}

async function updateDocument(requestData, endPoint) {
    const url = `${serviceURL}${endPoint}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(false);
        });
}

async function drawDocument(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.DRAW_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(false);
        });
}

async function saveDocument(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(err);
        });
}

async function saveMultipleDocument(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.MULTIPLE_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(err);
        });
}

async function signDocument(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SIGN_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200) {
                return Promise.reject(response);
            }
            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return err;
        });
}

async function validateDocument(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;

    let data = new FormData();
    data.append('document', requestData.file);

    return httpUploadFileService(method, {}, data, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return Promise.resolve(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(err);
        });
}

async function validateDocumentById(hash) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_DOCUMENT}/${hash}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = {
        'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON,
    };

    return httpService(method, header, {}, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject(response);
            }
            return Promise.resolve(response);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                hash,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(err);
        })
}

async function validateDocumentByIdAndTimestamp(documentID, timestamp) {
  const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_DOCUMENT}/${documentID}/${timestamp}`;
  const method = httpConstants.METHOD_TYPE.POST;
  const header = {
    'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON,
  };

  return httpService(method, header, {}, url)
    .then((response) => {
      if (!response.success && response.responseCode !== 200 && !response.responseData) {
        return Promise.reject(response);
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      httpErrorHandle({
        url,
        documentID,
        err: (err && err.message) ? err.message : err,
      });
      return Promise.reject(err);
    })
}

async function removeSubUser(userIDtoDelete, user) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.REMOVE_SUB_USER}${userIDtoDelete}`;
    const method = httpConstants.METHOD_TYPE.DELETE;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, {user}, url)
        .then((response) => {
            if (!response.success) {
                return Promise.reject();
            }
            return Promise.resolve(response);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                userIDtoDelete,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(err);
        })
}


async function addRating(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.RATINGS}`;
    const method = httpConstants.METHOD_TYPE.POST;

    return httpService(method, null, requestData, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return response.responseData;
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject(err);
        });
}


async function billingRequest(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.BILLING_REQUEST}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return response.responseData;
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject();
        });
}

async function updateSubUserDetails(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.UPDATE_SUB_USER}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success) {
                return Promise.reject();
            }
            return Promise.resolve(response);
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject();
        });
}


async function getSubscriptionPlan(isValidateSession = true) {
    if (isValidateSession) {
        await firebase._validateSession();
    }

    const url = `${serviceURL}${httpConstants.API_END_POINT.SUBSCRIPTION_PLANS}`;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpGetService(url, header)
        .then((response) => {
            if (!response.success || !response.responseData || !response.responseData.length) {
                return Promise.reject();
            }
            return Promise.resolve(response.responseData);
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject();
        });
}

async function getSubscriptionDetails(openPayCustomerID, subscriptionID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SUBSCRIPTION_ENDPOINT}${openPayCustomerID}/${subscriptionID}`;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpGetService(url, header)
        .then((response) => {
            if (!response.success || !response.responseData) {
                return Promise.resolve(false);
            }
            return Promise.resolve(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                openPayCustomerID,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.resolve(false);
        });
}

async function saveCards(request) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.SAVE_CARD}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, request, url)
        .then(async (response) => {
            if (response.success && response.responseData) {
                // TODO: Cookie
                const userData = sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.USER);
                if (response.responseData.customer && response.responseData.customer.id) {
                    const userDetails = await this._getUserFromUserID(userData.userID);
                    userData.openPayCustomerID = response.responseData.customer.id;
                    userDetails.openPayCustomerID = response.responseData.customer.id;
                    firebase._updateProfileDetails(userData.userID, userDetails);
                }
                sessionManager.setDataInCookies(userData, genericConstants.COOKIES_KEY.USER);
                if (response.responseData.card) {
                    userData['card'] = response.responseData.card;
                }
                return Promise.resolve(userData);
            }
            return Promise.reject(response);
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject(err);
        });
}

async function getCards(customerID) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.GET_CARD}${customerID}`;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpGetService(url, header)
        .then((response) => {
            if (response.success && response.responseData) {
                return Promise.resolve(response.responseData);
            }
            return Promise.reject();
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject();
        });
}

async function updateCard(request) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.SAVE_CARD}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, request, url)
        .then((response) => {
            if (response.success && response.responseData) {
                return Promise.resolve(response.responseData);
            }
            lhtLogs("err-", response, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject(response);
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject(err);
        });
}

async function subscribeUser(request, planObj) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.SUBSCRIBE_USER}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, request, url)
        .then((response) => {
            if (response.success && response.responseData) {
                // TODO: Cookie
                let userData = sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.USER);
                let subscriptionObj = {
                    userID: userData.userID,
                    isSubscribed: true,
                    subscriptionID: response.responseData.id,
                    purchasedDate: response.responseData.creation_date || '',
                    expiryDate: response.responseData.period_end_date || '',
                    status: response.responseData.status || '',
                    planName: planObj.name,
                    amount: planObj.amount,
                    metadata: planObj.metadata,
                    planID: planObj.id,
                    transactionID: response.responseData.id || '',
                    isSubscriptionCancelled: response.responseData.cancel_at_period_end
                };
                userData.openPayCustomerID = response.responseData.customer_id;
                userData['subscription'] = new SubscriptionModel(subscriptionObj);
                return Promise.resolve(userData);
            }
            return Promise.reject(response);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                request,
                planObj,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject(err);
        });
}

async function unsubscribeUser(request) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.UNSUBSCRIBE_USER}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, request, url)
        .then((response) => {
            if (response.success && response.responseData) {
                return Promise.resolve(true);
            }
            return Promise.reject();
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                request,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject();
        });
}

async function emailDocument(requestData, isValidateSession = true) {
    if (isValidateSession) {
        await firebase._validateSession();
    }

    const url = `${serviceURL}${httpConstants.API_END_POINT.SEND_EMAIL}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success) {
                return Promise.reject();
            }
            return Promise.resolve(response);
        })
        .catch((err) => {
            lhtLogs("err-", err, genericConstants.ERROR_TYPE.ERROR);
            return Promise.reject();
        });
}

async function shareObservers(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.SHARE_OBSERVERS}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success) {
                return Promise.reject();
            }
            return Promise.resolve(response);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject();
        });
}

async function shareDocument(requestData) {
    await firebase._validateSession();

    const url = `${serviceURL}${httpConstants.API_END_POINT.SHARE_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success) {
                return Promise.reject();
            }
            return Promise.resolve(response);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject();
        });
}


async function signDocumentUsingSat(data, updateDocumentRequest = null, isValidateSession = true) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SAT_SIGN_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    if (isValidateSession) {
        await firebase._validateSession();
    }

    if (updateDocumentRequest) {
        data.document = updateDocumentRequest;
    }

    return httpService(method, header, data, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }

            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData: data,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject();
        });
}

function getSVGCoordinates(requestObj) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SIGN_SVG_COORDINATES}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestObj, url)
        .then((response) => {
            if (!response.success && response.responseCode !== 200 && !response.responseData) {
                return Promise.reject();
            }
            return response.responseData;
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData: requestObj,
                err: (err && err.message) ? err.message : err,
            });
            return Promise.reject();
        });
}

// FIXME: Move in another folder
function getStringByType(key, type) {
    switch(type) {
        case 'psc':
            const ext = path.extname(key);
            const baseName = path.basename(key, ext);
            return `${baseName}.asn`;
        case 'blockchain':
            return `certificate_${key}`;
        case 'psc-pdf':
            const extpdf = path.extname(key);
            const baseNamePdf = path.basename(key, extpdf);
            return `constancy_${baseNamePdf}.pdf`;
        case 'ratification':
            const extNameRatificationPdf = path.extname(key);
            const baseNameRatificationPdf = path.basename(key, extNameRatificationPdf);
            return `ratification_${baseNameRatificationPdf}.pdf`;
        case 'ratification-guide':
            const extNameRatificationGuidePdf = path.extname(key);
            const baseNameRatificationGuidePdf = path.basename(key, extNameRatificationGuidePdf);
            return `ratificationguide_${baseNameRatificationGuidePdf}.pdf`;
        default:
            return key;
    }
}

async function downloadDocument(key, fileName, type = 'download') {
    let fileURL = await getSignedURL(getStringByType(key, type));
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = (type === 'psc') ? '_self' :'_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
        save.download = fileName || filename;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href;
        } else {
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
    return true;
}

async function validateBiometricID(requestData) {

    const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_BIOMETRIC_ID}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function validateBiometricIDAndFace(requestData) {

    const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_BIOMETRIC_ID_AND_FACE}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function identityProcess(requestData) {

    const url = `${serviceURL}${httpConstants.API_END_POINT.IDENTITY_PROCESS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function validateBiometricPassport(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_BIOMETRIC_PASSPORT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function checkBiometricAttempts(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.CHECK_BIOMETRIC_ATTEMPTS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function checkBiometricCredits(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.CHECK_BIOMETRIC_CREDITS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function useBiometricCredits(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.USE_BIOMETRIC_CREDITS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function retryBiometric(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.RETRY_BIOMETRIC}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function identityRetry(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.IDENTITY_RETRY}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function getBiometricDocument(documentID, biometricID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.GET_BIOMETRIC_DOCUMENT}/${documentID}/${biometricID}`;
    const method = httpConstants.METHOD_TYPE.GET;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };
    return httpService(method, header, null, url)
        .then((response) => {
            if (!response || !response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
}

async function getTags(requestData, userID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.TAGS}/${userID}`;
    const method = httpConstants.METHOD_TYPE.GET;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return response.responseData;
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                requestData,
                userID,
                err,
            });
            return Promise.reject(err);
        });
}


async function createTag(requestData, userID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.TAGS}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return response.responseData;
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

async function removeTag(requestData, userID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.TAGS}`;
    const method = httpConstants.METHOD_TYPE.DELETE;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return response.responseData;
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

async function updateDocumentTags(requestData, documentID, userID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.DOCUMENT}/${documentID}/tag?userID=${userID}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return new DocumentModel(response.responseData);
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

async function updateDocumentsTags(requestData, userID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.DOCUMENTS_TAGS}?userID=${userID}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }

            return response.responseData;
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

async function validateDocumentUrl(dataUrl) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.VALIDATE_URL}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON };

    return httpService(method, header, dataUrl, url)
        .then((response) => {
            if (!response.success || response.responseCode !== 200 || !response.responseData) {
                return Promise.reject(response);
            }
            return response.responseData;
        })
        .catch((err) => {
            httpErrorHandle({
                url,
                dataUrl,
                err: err && err.message ? err.message : err,
            });
            return Promise.reject(err);
        });
}

async function massiveSignDocument(requestData, isValidateSession = true) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.MASSIVE_SIGN_DOCUMENT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
    .catch((err) => {
        httpErrorHandle({
            url,
            requestData,
            err: (err && err.message) ? err.message : err,
        });
        return Promise.reject(err);
    });
}

async function saveFormat(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SAVE_FORMAT}`;
    const method = httpConstants.METHOD_TYPE.POST;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
    .catch((err) => {
        httpErrorHandle({
            url,
            requestData,
            err: (err && err.message) ? err.message : err,
        });
        return Promise.reject(err);
    });
}

async function deleteFormat(requestData) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SAVE_FORMAT}`;
    const method = httpConstants.METHOD_TYPE.PUT;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, requestData, url)
    .catch((err) => {
        httpErrorHandle({
            url,
            requestData,
            err: (err && err.message) ? err.message : err,
        });
        return Promise.reject(err);
    });
}

async function getFormats(userID) {
    const url = `${serviceURL}${httpConstants.API_END_POINT.SAVE_FORMAT}/${userID}`;
    const method = httpConstants.METHOD_TYPE.GET;
    const header = { 'Content-Type': httpConstants.CONTENT_TYPE.APPLICATION_JSON, 'Authorization': `Bearer ${LocalStorageRepository.getAccessToken()}` };

    return httpService(method, header, {}, url)
    .catch((err) => {
        httpErrorHandle({
            url,
            res:{},
            err: (err && err.message) ? err.message : err,
        });
        return Promise.reject(err);
    });
}

async function getUserLegalPolicies(userId) {
    try {
        const payload = await HttpInterceptorService.run(async () => {
            const config = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: '',
                },
            };
            const response = await fetch(`${serviceURL}${httpConstants.API_END_POINT.USER_LEGAL_POLICY}/${userId}`, config);
            return await response.json();
        });

        if (isNotHttpCodeSuccess(payload)) {
            throw new Error(`Status code: ${payload.responseCode}`);
        }

        if (!payload.responseData) {
            throw new Error(`Data empty`);
        }

        return payload.responseData;
    } catch (e) {
        console.warn('[GET_USER_LEGAL_POLICY] error: ', e);
        return null;
    }
}


async function getEndorsementsPendingToSign(requestData) {
    try {
        const payload = await HttpInterceptorService.run(async () => {
            const config = {
                method: 'POST',
                body: JSON.stringify(requestData),
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${LocalStorageRepository.getAccessToken()}`,
                },
            };

            const response = await fetch(`${serviceURL}${httpConstants.API_END_POINT.MASSIVE_ENDORSEMENTS_TO_SIGN}`, config);
            return await response.json();
        });
        if (isNotHttpCodeSuccess(payload)) {
            throw new Error(`Access code: ${payload.responseCode}`);
        }
        if (!payload.responseData) {
            throw new Error(`Data empty`);
        }
        return payload.responseData;
    } catch (e) {
        console.warn('[GET_MASSIVE_ENDORSEMENTS_TO_SIGN] error: ', e);
        return null;
    }
}

export {
    saveDocument,
    saveMultipleDocument,
    getDocument,
    getDocumentBYID,
    deleteDocument,
    signDocument,
    shareDocument,
    signDocumentUsingSat,
    emailDocument,
    updateDocument,
    addRating,
    billingRequest,
    validateDocument,
    removeSubUser,
    updateSubUserDetails,
    getSubscriptionPlan,
    saveCards,
    getCards,
    updateCard,
    subscribeUser,
    unsubscribeUser,
    getSVGCoordinates,
    searchFileName,
    pendingDocuments,
    advancedSearch,
    advancedSearchEmails,
    advancedSearchExportExcel,
    advancedSearchBulkDownloadDocuments,
    generateApiKey,
    downloadDocument,
    getSubscriptionDetails,
    getCountPendingDocuments,
    getSharedDocuments,
    validateBiometricID,
    validateBiometricIDAndFace,
    validateBiometricPassport,
    checkBiometricAttempts,
    checkBiometricCredits,
    useBiometricCredits,
    retryBiometric,
    identityRetry,
    getBiometricDocument,
    getDocumentByDocumentIDSignatory,
    getTags,
    createTag,
    removeTag,
    updateDocumentTags,
    validateDocumentUrl,
    massiveSignDocument,
    drawDocument,
    saveFormat,
    deleteFormat,
    getFormats,
    identityProcess,
    getDocumentBySharedWithID,
    getUserLegalPolicies,
    shareObservers,
    updateDocumentsTags,
    getEndorsementsPendingToSign,
    validateDocumentById,
  validateDocumentByIdAndTimestamp,
};
