import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { cleanNotification, cleanCustomDialog } from '../actions';
import NotificationContainer from '../containers/notification-dialog.container';
import ToastNotification from '../components/atoms/toast.component';
import DialogCustom from '../components/organisms/dialog-custom.component';

const Notification = ({
  notification,
  cleanNotification,
  customDialog,
  cleanCustomDialog,
  children,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);
  const hasNotification = notification && notification.isShown && notification.notificationId;
  const hasCustomDialog = customDialog && customDialog.isShown && customDialog.dialogId;
  
  useEffect(() => {
    if (hasNotification) {
      setOpenDialog(true);
    }
  }, [hasNotification]);

  useEffect(() => {
    if (hasCustomDialog) {
      setPropsDialog(customDialog && customDialog.data ? customDialog.data : null);
      setOpenDialog(true);
    }
  }, [hasCustomDialog]);

  const handleCloseManual = () => {
    setOpenDialog(false);
    if (hasNotification) {
      cleanNotification();
    }
    if (hasCustomDialog) {
      cleanCustomDialog();
    }
  };

  return (
    <>
      {hasNotification && (
        <DialogCustom isOpen={openDialog} type={notification.type} onClose={handleCloseManual} >
          <NotificationContainer onCloseNotification={handleCloseManual} />
        </DialogCustom>
      )}
      {hasCustomDialog && (
        <DialogCustom
          isOpen={openDialog}
          type={customDialog.type}
          className={propsDialog?.className || ''}
          onClose={handleCloseManual}
          hasCustomClose={propsDialog?.hasCustomClose || false}
          closeElement={propsDialog?.closeElement || <></>}
        >
          {propsDialog?.componentDialog || <></>}
        </DialogCustom>
      )}
      <ToastNotification />
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  customDialog: state.dialog,
});

const mapDispatchToProps = {
  cleanNotification,
  cleanCustomDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
