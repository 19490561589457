export class LocalStorageRepository {
  static setAccessToken(token) {
    localStorage.setItem('auth-token', token);
  }

  static getAccessToken() {
    return localStorage.getItem('auth-token');
  }

  static cleanToken() {
    localStorage.removeItem('auth-token');
  }

  static setRefreshAccessToken(refreshToken) {
    localStorage.setItem('auth-refresh-token', refreshToken);
  }

  static getRefreshAccessToken() {
    return localStorage.getItem('auth-refresh-token');
  }

  static cleanRefreshAccessToken() {
    localStorage.removeItem('auth-refresh-token');
  }

  static setIsExpiredToken(isExpired = true) {
    localStorage.setItem('is-expired-token', isExpired);
  }

  static cleanIsExpiredToken() {
    localStorage.removeItem('is-expired-token');
  }

  static setSessionToken(sessionToken) {
    localStorage.setItem('session-token', sessionToken);
  }

  static getSessionToken() {
    return localStorage.getItem('session-token');
  }

  static cleanSessionToken() {
    localStorage.removeItem('session-token');
  }

  static getJsonItem(name) {
    try {
      const raw = localStorage.getItem(name);
      const data = JSON.parse(raw);
      return data;
    } catch (err) {
      return {};
    }
  }
}
