const initialState = {
  selectedTag: '',
  tags: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'ADD_TAG': {
        return {
          ...state,
          tags: [...state.tags, action.payload.tag].sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }),
        }
    }
    case 'REMOVE_TAG': {
      return {
        ...state,
        tags: state.tags.filter((item) => item.name !== action.payload.tag.name),
      };
    }
    case 'SET_TAG': {
      return {
        ...state,
        selectedTag: action.payload.selectedTag,
      };
    }
    case 'GET_TAGS': {
      return {
        ...state,
        tags: action.payload.tags,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
