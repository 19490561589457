import React from 'react';
import verifyEmailDialog from '../common/verifyEmailPopup';

export const EmailAction = (props) => {
    return (
        <>
            {props.openVerifyEmailPopup ? verifyEmailDialog(props, props.dialogType, false) : null}
        </>
    );
};
