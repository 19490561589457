/**
 * Created by rahul on 1/7/19.
 */
import {actionTypeConstant,languageConstant} from '../factories/constant';
export default function(state = {lang:languageConstant.ES}, action) {
    switch (action.type) {
        case actionTypeConstant.LOCALE_SET:
            return {
                lang: action.lang
            };
        default:
            return state;
    }
}