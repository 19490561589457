import React from 'react';
import {Column, Row} from 'simple-flexbox';
import {FormattedMessage} from "react-intl";

import Form from "../common/formComponent";
import Loader from "../common/buttonLoader";
import messages from "../../utils/messages";
import {errorConstants} from "../../factories/constant";

let borderBottom = {borderBottom: '1px solid #e6ecfb'};

const subUserAccountSetupComponent = (props) => {
    let titleMessage = messages[props.language][errorConstants.CHANGE_PASSWORD_ERROR];
    return (
        <div>
            <Column horizontal='start' className="width-10-per pl-50 pt-50" vertical='center'>
                <div className="">
                    <a onClick={props.redirectToHomePage}><img className="footer-img" alt="" src="images/logo_black.svg"/></a>
                </div>
            </Column>
            <Row horizontal='center'>
                <Column horizontal={"center"} vertical={"center"}>
                    <div className="">
                        {headingWithBorder(props, "profile.change_password.title")}</div>
                    <div className=" pt-34 profile-change-pwd" flex="1" wrap={true}>
                        <Column flex="40" className="p-0-10">
                            <Form id="change-password" onSubmit={props.changePassword}>
                                <div className="pt-28">
                                    <div className="fs-14 pb-md text-grey-bali-hai">
                                        <FormattedMessage id="profile.change_password.new_password"/>
                                    </div>
                                    <input className="input-box min-width-225 form-control"
                                        required={true}
                                        pattern="(?=.*\d)(?=.*[A-Z]).{8,}"
                                        title={titleMessage}
                                        name="newPassword" onChange={props.handleChange}
                                        id="new-password" type="password"/>
                                    <div className="invalid-feedback"/>
                                </div>
                                <div className="pt-28">
                                    <div className="fs-14 pb-md text-grey-bali-hai">
                                        <FormattedMessage id="profile.change_password.confirm_password"/>
                                    </div>
                                    <input className="input-box min-width-225 form-control"
                                        required={true}
                                        pattern="(?=.*\d)(?=.*[A-Z]).{8,}"
                                        title={titleMessage}
                                        name="confirmPassword" onChange={props.handleChange}
                                        id="confirm-password" type="password"/>
                                    <div className="invalid-feedback"/>
                                </div>
                                <div className="fs-14 text-center">
                                    {props.error.length ?
                                        <div className="custom-invalid-feedback"><FormattedMessage id={props.error}/>
                                        </div> : ""}
                                </div>
                                <div className="pt-50 text-center">
                                    <button type="submit"
                                            disabled={props.loader}
                                            className="fs-16 text-white width-210 submit-btn bg-Color-green-cyan">
                                        {!props.loader ? <FormattedMessage id="profile.button.save"/> : ""}
                                    </button>
                                    {props.loader ? <Loader/> : ""}

                                </div>
                            </Form>
                        </Column>
                    </div>
                </Column>
            </Row>
        </div>
    );
};

const headingWithBorder = (props, headingText) => {
    return (<div>
            <div className="fs-20  pl-md pt-xs"><FormattedMessage
                id={headingText}/></div>
            <div className="pt-28" style={borderBottom}></div>
        </div>
    );
};

export default subUserAccountSetupComponent;