import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Loader = ({ isPageLoader, isTransparent, customClass, label, labelClass }) => {
  return (
    <div className={`${customClass ?? ''} wt-loader ${isPageLoader ? 'wt-loader--fullsize':''} ${isTransparent ? 'wt-loader--transparent':''}`}>
      <div className="wt-loader__content">
        <div className="wt-loader__animation">
          <div className="wt-loader__progress"></div>
        </div>
        {label && (
          <div className={`wt-loader__label ${labelClass ?? ''}`}>
            <FormattedMessage id={label} />
          </div>
        )}
      </div>
    </div>
  );
};

Loader.defaultProps = {
  isPageLoader: false,
  isTransparent: false,
  customClass: '',
  label: '',
  labelClass:  '',
};

Loader.propTypes = {
  isPageLoader: PropTypes.bool,
  isTransparent: PropTypes.bool,
  customClass: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
};

export default Loader;
