/**
 * Created by rahul on 1/7/19.
 */
import {actionTypeConstant} from '../factories/constant';
export const localeSet = lang => (
{
    type: actionTypeConstant.LOCALE_SET,
    lang
});
export const setLocale = (lang) => {
    return dispatch => {
        localStorage.englishLang = lang;
        dispatch(localeSet(lang));
    };

};