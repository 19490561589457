import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';

import { genericConstants } from '../../factories/constant';

const ToastNotification = ({toastConfig}) => {
  const toastId = React.useRef(null);
  const CloseButton = ({ closeToast }) => (
    <button onClick={closeToast} className="Toastify__close-button"><i className="wt-icon-close"></i></button>
  );
  useEffect(() => {
    if (toastConfig && toastConfig.data && toastConfig.data.toastContent) {
      const toastPosition = toastConfig.data.position ?? toast.POSITION.TOP_RIGHT;
      let defaultConfig = {
        className: 'toast-notification',
        position: !isMobile ? toastPosition : 'top-left',
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: !isMobile,
      };
      if(toastConfig.data.permanent) {
        defaultConfig.autoClose = false;
      }
      if(toastConfig.data.toastIcon) {
        defaultConfig.icon = toastConfig.data.toastIcon;
      }
      if(toastConfig.data.hideIcon) {
        defaultConfig.icon = false;
      }
      switch(toastConfig.data.type) {
        case genericConstants.TOAST_TYPE.SUCCESS : 
          toast.success(toastConfig.data.toastContent, {
            icon: <><i className="wt-icon-check-circle"></i></>,
            ...defaultConfig,
            className: 'toast-notification toast-notification--success',
          });
        break;
        case genericConstants.TOAST_TYPE.ERROR : 
          toast.error(toastConfig.data.toastContent, {
            icon: <><i className="icon-warning-alert"></i></>,
            ...defaultConfig,
            className: 'toast-notification toast-notification--error',
          });
        break;
        case genericConstants.TOAST_TYPE.WARNING : 
          toast.warn(toastConfig.data.toastContent, {
            icon: <><i className="icon-warning-alert"></i></>,
            ...defaultConfig,
            className: 'toast-notification toast-notification--warning',
          });
        break;
        case genericConstants.TOAST_TYPE.INFO : 
          toast.info(toastConfig.data.toastContent, {
            icon: <><i className="icon-baseline"></i></>,
            ...defaultConfig,
            className: 'toast-notification toast-notification--info',
          });
        break;
        default: 
          toast(toastConfig.data.toastContent, {
            icon: false,
            ...defaultConfig,
          });
      }
    }
  }, [toastConfig]);

  return (
    <>
      <ToastContainer closeButton={CloseButton} />
    </>
  );
};

const mapStateToProps = (state) => ({
  toastConfig: state.toast,
});

export default connect(mapStateToProps, null)(ToastNotification);
