import AWS from 'aws-sdk';
import { genericConstants } from '../factories/constant';
import { getApiKey } from '../utils/index';

// import config from 'config';
// var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/++[++^A-Za-z0-9+/=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}};
const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
const bucketTemplateName = process.env.REACT_APP_S3_TEMPLATES_BUCKET_NAME;
const accessKey = getApiKey(process.env.REACT_APP_ACCESS_KEY);
const secretAccessKey = getApiKey(process.env.REACT_APP_SECRET_KEY);

const s3Object = () => {
  AWS.config.update({
    accessKeyId: accessKey,
    secretAccessKey,
  });

  return new AWS.S3();
};

export const uploadFileToS3AndGetObject = (fileObject, fileName, mimeType, isPublic = false, content = null, bucket = bucketName) => {

    const S3 = s3Object();

    const params = {
        ServerSideEncryption: 'AES256',
        Body: fileObject,
        Bucket: bucket,
        ContentType: mimeType,
        Key: fileName
    };

    if (isPublic) {
        params.ACL = 'public-read';
    }

    if (content) {
        params.ContentEncoding = 'base64';
        params.Body = new Buffer(params.Body.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    }

    return new Promise(function (resolve, reject) {
      S3.upload(params, function (err, object) {
        if (err) {
          return reject({code: err.code});
        }

        S3.getObject({ Bucket: bucket, Key: object.Key })
          .promise()
          .then((data) => resolve(data))
          .catch((err) => reject(err));
      });
    });
};


export const uploadFileToS3 = (fileObject, fileName, mimeType, isPublic = false, content = null, bucket = bucketName) => {
  const S3 = s3Object();

  const params = {
    ServerSideEncryption: 'AES256',
    Body: fileObject,
    Bucket: bucket,
    ContentType: mimeType,
    Key: fileName
  };

  if (isPublic) {
    params.ACL = 'public-read';
  }

  if (content) {
    params.ContentEncoding = 'base64';
    params.Body = new Buffer(params.Body.replace(/^data:image\/\w+;base64,/, ""), 'base64')
  }

  return new Promise(function (resolve, reject) {

    S3.upload(params, function (err, res) {
      let versionId = '';
      if (err) {
        reject({code: err.code});
      }

      const uploadParams = {
        Bucket: bucket,
        Prefix: fileName,
        MaxKeys: 1,
      };

      S3.listObjectVersions(uploadParams, function (err, data) {
        if (err) {
          reject({code: err.code});
        } else {
          if (data && data.Versions && data.Versions.length && data.Versions[0].VersionId) {
            versionId = data.Versions[0].VersionId;
          }
        }

        const response = { ...res, versionId };
        resolve(response);
      });
    });
  });
};

export const getObject = async (objectKey, getAllData = false, bucket = bucketName) =>{
    const params = {
        Bucket: bucket,
        Key: objectKey,
    };

    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey,
    });

    const s3 = new AWS.S3();
    const data = await s3.getObject(params).promise();

    if (!data && !data.Body) return false;

    return getAllData ? data : data.Body;
};

export const getSignedURL = (key, expiryTime = genericConstants.AWS.EXPIRY_TIME) => {
    if (!key) return;

    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey,
    });

    // create S3 instance
    const S3 = new AWS.S3({ logger: console, signatureVersion: 'v4' });
    const params = {
        Bucket: bucketName,
        Key: key,
        Expires: expiryTime,
    };
    return S3.getSignedUrl('getObject', params);
};

export const getImageBuffer = async (key) => {
    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey,
    });

    // create S3 instance
    const S3 = new AWS.S3({logger: console});
    const params = {
        Bucket: bucketName,
        Key: key
    };

    return new Promise(function (resolve, reject) {
        S3.getObject(params, function (err, data) {
            if (err) {
                reject(err);
            }

            const file = new Buffer(data.Body, 'binary');
            const imageBuffer = file.toString('base64');
            resolve(imageBuffer);
        });
    });
}

export const getUrlAWS = async (key, bucket = bucketName) => {
  if (!key) return;
  console.log('key', key)
  AWS.config.update({
      accessKeyId: accessKey,
      secretAccessKey,
  });
  const S3 = new AWS.S3({ logger: console, signatureVersion: 'v4' });
  const params = {
      Bucket: bucket,
      Key: key,
  };
  return S3.getSignedUrl('getObject', params);
}

export const getS3CSV = async (key) => {
    AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey,
    });

    // create S3 instance
    const S3 = new AWS.S3({logger: console});
    const params = {
        Bucket: bucketTemplateName,
        Key: key
    };

    return new Promise(function (resolve, reject) {
        S3.getObject(params, function (err, data) {
            if (err) {
                reject(err);
            }

            if(data.Body) {
                resolve(data.Body.toString());
            }
            resolve(data);
        });
    });
};
