import React from 'react';
import { FormattedMessage } from 'react-intl';

const AccessRestrictedModal = () => {
  return (
    <div className="document-restricted-dialog wt-dialog--center">
      <div className="wt-dialog__graphic wt-dialog--start">
        <img src="images/we-have-a-problem.svg" alt="access restricted" />
      </div>
      <div className="wt-dialog__title">
        <FormattedMessage id="access.restricted.dialog.title" />
      </div>
      <div className="wt-dialog__caption wt-dialog--end">
        <FormattedMessage id="access.restricted.dialog.description" />
      </div>
    </div>
  );
};

export default AccessRestrictedModal;
