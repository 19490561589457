import React from 'react';
import { FormattedMessage } from 'react-intl';
import MembershipCard from '../../atoms/membership-card.component';

const EnableSuscriptionNotice = () => {
  return (
    <div className="plans-notice plans-notice--with-actions">
      <div className="plans-notice__introduction">
        <div className="plans-notice__title">
          <span><FormattedMessage id="freeplan.payment.notice.title" /></span>
        </div>
        <div className="plans-notice__subtitle">
          <span className="plans-notice--warning">
            <strong><FormattedMessage id="freeplan.payment.notice.subtitle.document_limit" /></strong>
          </span>
        </div>
      </div>
      <div className="plans-notice__description">
        <div className="plans-notice__suscription">
          <FormattedMessage id="freeplan.payment.notice.suscription.document_limit" />
        </div>
      </div>
      <div className="plans-notice__content">
        <MembershipCard hasPromo />
      </div>
    </div>
  );
};

export default EnableSuscriptionNotice;
