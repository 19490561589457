import React, { Component } from 'react';
import BaseLayout from '../../components/layouts/base-layout.component';

import hocAux from '../../hoc/hocAux';
import firebase from '../../factories/firebase';
import userService from '../../services/user.service';

import { EmailAction } from './component';
import SignupVerify from '../../components/organisms/auth/signup-verify.component';
import { history } from '../../utils/history';
import { sessionManager } from '../../factories/sessionManager';
import { emailDocument } from '../../services/documentService';
import { errorConstants, genericConstants, screenPathConstants } from '../../factories/constant';
import { LocalStorageRepository } from '../../repositories/local-storage.repository';

class HelpAndSupportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      loader: true,
      queryParams: history.location.search,
      openVerifyEmailPopup: true,
      btnLoader: false,
      isResetPasswordSuccess: false,
      dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_SUCCESS,
      actionCode: '',
      passwordError: '',
      password: '',
      confirmPassword: '',
      emailID: '',
      createdByPayment: false,
      isVerifyEmail: false,
    };
  }

    componentDidMount = async () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.handleFirebaseEmailAction()
    };

    updateWindowDimensions = () => {
        var footerHeight = document.getElementById('footer')? document.getElementById('footer').clientHeight: 0;
        var windowHeight = window.innerHeight;

        this.setState({width: window.innerWidth, height: windowHeight - footerHeight});
    };

    getURLParameter = (param) => {
      if (!window.location.search) {
        return;
      }
      var m = new RegExp(param + '=([^&]*)').exec(window.location.search.substring(1));
      if (!m) {
        return;
      }
      return decodeURIComponent(m[1]);
    }

    getParameterByName = (param) => {
      if (!this.state.queryParams) {
        return;
      }
      const urlSearchParams = new URLSearchParams(history.location.search);
      return urlSearchParams.get(param);
    };

    handleFirebaseEmailAction = () => {

      const mode = this.getParameterByName('mode');
      const email = this.getURLParameter('email');
      const actionCode = this.getParameterByName('oobCode');

      switch (mode) {
        case genericConstants.FIREBASE_ACTION_CODE.VERIFY_EMAIL:
            this.handleVerifyEmail(actionCode, email);
            break;
        case genericConstants.FIREBASE_ACTION_CODE.RESET_PASSWORD:
            this.handleResetPasswordEmail(actionCode);
            break;
      }
    };

    handleVerifyEmail = async (actionCode, email) => {
      this.setState({
        loader: false,
        isVerifyEmail: true,
        dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL,
      });
      const data = await firebase._handleVerifyEmail(actionCode, email).catch(()=> {
        this.setState({
          dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_FAILURE
        });
        return false;
      });
      console.log("data verify", data);
      if (!data) {
        this.setState({
          dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_FAILURE
        });
        return false;
      }

      LocalStorageRepository.setAccessToken(data.token);
      LocalStorageRepository.setRefreshAccessToken(data.refreshToken);
      LocalStorageRepository.setSessionToken(data.sessionToken);

      userService.userVerify({ email });
      this.sendWelcomeEmail(data.email);

      const createdByPayment = await firebase._verifyUpdatePassword({
        email,
        code: actionCode,
      });

      this.setState({
        dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.VERIFY_EMAIL_SUCCESS
      });
      history.push(screenPathConstants.TOKEN_LOGIN);
    };

    sendWelcomeEmail = async (res) => {
      if (!res) {
        return false;
      }

      const language = localStorage.getItem('englishLang');

      const requestObj = {
        postedTo: res,
        language: language || 'es',
        type: 'welcomeWeeSign'
      };

      await emailDocument(requestObj, false);
    }

    handleChange = (e) => {
        if (!e)
            return;
        e.preventDefault();

        const {name, value} = e.target;
        if (!name || !value)
            return;

        this.setState({[name]: value, passwordError: ''});
    };

  onChangeCaptcha = (value) => {
    console.log('Captcha value:', value);
  };

    handleResetPasswordEmail = (actionCode) => {
      this.setState({
        actionCode,
        loader: false,
        dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.RESET_PASSWORD_MAIL
      });
    };

    closeVerifyEmailPopup = () => {
      if (!this.state.createdByPayment) {
        this.setState({ openVerifyEmailPopup: !this.state.openVerifyEmailPopup });
        sessionManager.removeDataFromCookies(genericConstants.COOKIES_KEY.USER);
        history.push(screenPathConstants.LOGIN);
      } else {
        this.setState({
          loader: false,
          dialogType: genericConstants.EMAIL_DIALOG_TEMPLATE.RESET_PASSWORD_MAIL
        });
      }
    };

    resetPassword = async (recaptcha, password) => {
      try {
        this.setState({ btnLoader: true, passwordError: '' });
        let updatePassword;
        if (!this.state.createdByPayment) {
          updatePassword = await firebase._resetPasswordResetCode(this.state.actionCode, {password, recaptcha});
        } else {
          updatePassword = await firebase._updateVerifiedUserPassword(this.state.emailID, {
            password,
            code: this.state.actionCode,
          });
        }
        if (updatePassword && updatePassword.data) {
          if (!this.state.createdByPayment && this.state.emailID) {
            await userService.passwordReset({ email: this.state.emailID });
          }
          this.setState({
            btnLoader: false,
            createdByPayment: false,
            isResetPasswordSuccess: true,
          });
          history.push({
            pathname: screenPathConstants.LOGIN,
            search: '?isPasswordReset=true',
          });
        } else {
          throw new Error(`${updatePassword.message ?? 'EMPTY DATA'}`);
        }
      } catch(e) {
        console.log('RESET_PASSWORD_FAILURE => ', e);
        this.setState({
          btnLoader: false,
          passwordError: errorConstants.RESET_PASSWORD_FAILURE
        });
      };
    };

    render() {
        return (
          <>
            {this.state.isVerifyEmail ? (
              <SignupVerify dialogType={this.state.dialogType} />
            ) : (
              <BaseLayout>
                <EmailAction
                    recaptchaRef={this.recaptchaRef}
                    setRecaptchaRef={this.setRecaptchaRef}
                    closeVerifyEmailPopup={this.closeVerifyEmailPopup}
                    handleChange={this.handleChange}
                    onFormSubmit={this.resetPassword}
                    loader={this.state.loader}
                    width={this.state.width}
                    height={this.state.height}
                    btnLoader={this.state.btnLoader}
                    passwordError={this.state.passwordError}
                    openVerifyEmailPopup={this.state.openVerifyEmailPopup}
                    isResetPasswordSuccess={this.state.isResetPasswordSuccess}
                    dialogType={this.state.dialogType}
                    createdByPayment={this.state.createdByPayment}
                  />
              </BaseLayout>
            )}
          </>
        )
    }
}

export default hocAux(HelpAndSupportContainer);
