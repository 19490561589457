import { actionTypeConstant } from '../factories/constant';

const emptyNotification = {
  notificationId: '',
  isShown: false,
  type: '',
  data: {},
};

const initialState = emptyNotification;

export default function(state = initialState, action) {
  switch(action.type) {
    case actionTypeConstant.SHOW_NOTIFICATION: {
      return {
        ...action.payload,
        isShown: true,
      }
    }
    case actionTypeConstant.CLEAR_NOTIFICATION: {
      return emptyNotification;
    }
    default: {
      return state;
    }
  }
}
