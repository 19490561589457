import {Component} from "react";
import {connect} from 'react-redux';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React from "react";
import $ from "jquery";

import hocAux from "../../hoc/hocAux";
import {history} from "../../utils/history";
import SubUserSetupComponent from "./component";
import {decodeBase64} from "../../utils";
import {errorConstants, genericConstants, screenPathConstants} from "../../factories/constant";
import firebase from '../../factories/firebase'
import {openSnackbar} from "../common/notification";

class SubUserSetupContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                userID: "",
                emailID: "",
                password: "",
                confirmPassword: "",
                newPassword: ""
            },
            error: "",
            loader: false,
            language: this.props.locale.lang
        }
    }

    componentDidMount() {
        console.log(this.props);
        let user = this.state.user;
        if (this.props.match.params && this.props.match.params.userID) {
            user.userID = this.props.match.params.userID;
        }

        if (this.props.match.params && this.props.match.params.token) {
            user.password = decodeBase64(this.props.match.params.token);
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        let user = this.state.user;
        user[name] = value;
        this.setState({user: user, error: ''});
    };

    redirectToPage = (page = screenPathConstants.LOGIN) => {
        history.push({pathname: page});
    };

    changePassword = async (e) => {
        e.preventDefault();
        this.setState({loader: true});
        if (this.state.user.confirmPassword !== this.state.user.newPassword) {
            $("#change-password")[0].reset();
            this.setState({error: errorConstants.PASSWORD_AND_CONFIRM_PASSWORD, loader: false});
            return;
        }
        let response = await firebase._changePasswordForSubUser(this.state.user).catch(err => {
            this.setState({loader: false});
            this.redirectToPage();
            this.props.openSnackbar("register.verify_email.failure.message", genericConstants.TOAST_TYPE.ERROR);
            console.log(err);
        });

        if (!response) {
            this.setState({loader: false});
            this.redirectToPage();
            this.props.openSnackbar("register.verify_email.failure.message", genericConstants.TOAST_TYPE.ERROR);
        }
        this.setState({loader: false});
        this.redirectToPage(screenPathConstants.LOGIN);
        openSnackbar("change.password.success", genericConstants.TOAST_TYPE.SUCCESS);
    };

    render() {
        return (
            <MuiThemeProvider>
                <div>
                    <SubUserSetupComponent
                        redirectToHomePage={this.redirectToPage}
                        changePassword={this.changePassword}
                        handleChange={this.handleChange}
                        language={this.state.language}
                        error={this.state.error}
                        loader={this.state.loader}
                    />
                </div>
            </MuiThemeProvider>);
    }
}


const mapStateToProps = (state) => {
    return {user: state.user, locale: state.locale}
};
export default connect(mapStateToProps)(hocAux(SubUserSetupContainer));