import React, { Suspense, lazy} from 'react';
import {Router, Route, Redirect, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {history} from './utils/history';
import messages from './utils/messages';
import {IntlProvider} from 'react-intl';
import { isWindows } from 'react-device-detect';

import firebaseEmailActionContainer from './modules/firebaseEmailAction/container';
import SubUserAccountSetup from './modules/subUserAccountSetup/container';
import {genericConstants, languageConstant, templatesConfig, documentsStatus} from './factories/constant';
import {changeLanguage, getApiKey} from '../src/utils/index';

import {sessionManager} from './factories/sessionManager';
import firebase from './factories/firebase';
import UserModel from './factories/models/userModel';
import {updateUserSuccess} from './actions/index';
import Loader from './components/atoms/loader.component';
import ProtectedRoutes from './hoc/protected-routes.hoc';
import Notification from './hoc/notification.hoc';
import Socket from './hoc/socket.hoc';
import OnboardingTour from './hoc/onboarding-tour.hoc';

let openPayID = getApiKey(process.env.REACT_APP_OPEN_PAY_ID);
let openPayApiKey = getApiKey(process.env.REACT_APP_OPEN_PAY_API_KEY);
let isForSandBox = process.env.REACT_APP_OPEN_PAY_FOR_SANDBOX === 'true';

const PreviewFileContainer = withRouter(lazy(() => import('./modules/previewFile/container')));
const ViewFileContainer = withRouter(lazy(() => import('./modules/viewFile/container')));
const OnlyMeSigningContainer = withRouter(lazy(() => import('./modules/signingAuthority/onlyMe/container')));
const MeAndOtherSigningContainer = withRouter(lazy(() => import('./modules/signingAuthority/meAndOther/container')));
const SigningAuthorityContainer = withRouter(lazy(() => import('./modules/signingAuthority/container')));
const OtherSigningContainer = withRouter(lazy(() => import('./modules/signingAuthority/other/container')));
const FormSignatoryContainer = withRouter(lazy(() => import('./containers/FormSignatory')));
const SignatoryContainer = withRouter(lazy(() => import('./containers/SignatoryV3')));
const Observer = withRouter(lazy(() => import('./containers/Observer')));
const BiometricContainer = withRouter(lazy(() => import('./modules/biometric/container')));
const SatSignatoryContainer = withRouter(lazy(() => import('./modules/satSignature/container')));
const TemplateSignatoryContainer = withRouter(lazy(() => import('./containers/TemplateSignatory')));
const ValidateUrlContainer = withRouter(lazy(() => import('./modules/validateUrl/container')));
const DocumentCreatorContainer = withRouter(lazy(() => import('./containers/DocumentCreator')));
const AuthRedirectContainer = withRouter(lazy(() => import('./containers/AuthRedirect')));
const MassivaSignatureLisContainer = withRouter(lazy(() => import('./containers/MassiveSignatureList')));
const IdentityFlowWithoutDocument = withRouter(lazy(() => import('./containers/identity-flow-without-document.container')));
const IdentityFlowContainer = withRouter(lazy(() => import('./containers/IdentityFlow')));
const AuthWithToken = withRouter(lazy(() => import('./containers/authentication-with-token.container')));

const InvoicePage = withRouter(lazy(() => import('./pages/config/invoice.page')));
const InboxPage = withRouter(lazy(() => import('./pages/documents/inbox.page')));
const MassiveSignaturePage = withRouter(lazy(() => import('./pages/documents/massive-signature.page')));
const MassiveSignatureDetailPage = withRouter(lazy(() => import('./pages/documents/massive-signature-detail.page')));
const TemplatesPage = withRouter(lazy(() => import('./pages/templates/templates.page')));
const ProfilePage = withRouter(lazy(() => import('./pages/config/profile.page')));
const UsersPage = withRouter(lazy(() => import('./pages/config/users.page')));
const ValidationPage = withRouter(lazy(() => import('./pages/validation.page')));
const ValidateDocumentIdPage = withRouter(lazy(() => import('./pages/validate-document-id.page')));
const RegisterPage = withRouter(lazy(() => import('./pages/register.page')));
const LoginPage = withRouter(lazy(() => import('./pages/login.page')));
const EndorsementsValidationPage = withRouter(lazy(() => import('./pages/endorsements/endorsements-validation.page')));
const TeranAuthPage = withRouter(lazy(() => import('./pages/auth/teran-auth.page')));
const TemplateConfigPage = withRouter(lazy(() => import('./pages/templates/template-config.page')));
const TemplateSinglePage = withRouter(lazy(() => import('./pages/templates/template-single.page')));
const TemplateMassivePage = withRouter(lazy(() => import('./pages/templates/template-massive.page')));
const VoBoPage = withRouter(lazy(() => import('./pages/vobo.page')));
const CancelDocumentPage = withRouter(lazy(() => import('./pages/documents/cancel-document.page')));
const CancelDocumentReviewPage = withRouter(lazy(() => import('./pages/documents/cancel-document-review.page')));
const SpecialEndorsementPage = withRouter(lazy(() => import('./pages/endorsements/special-endorsement.page')));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {orientation: false};

    if (localStorage.englishLang === languageConstant.EN)
      document.title = genericConstants.ENGLISH_TITLE;
    else
      document.title = genericConstants.SPANISH_TITLE;
    window.OpenPay.setSandboxMode(isForSandBox);
    window.OpenPay.setId(openPayID);
    window.OpenPay.setApiKey(openPayApiKey);
    changeLanguage(localStorage.englishLang);
  }

  setOrientation = (orientation) => {
    const screen = window.screen;
    const ableToShow = screen && screen.width < 1000 && (orientation == 90 || orientation == -90);
    this.setState({orientation: ableToShow});
  };
  getOrientation = () => {
    window.addEventListener('load', (event) => {
      this.setOrientation(event.currentTarget.orientation);
    }, false);

    window.addEventListener('orientationChange', (event) => {
      this.setOrientation(event.currentTarget.orientation);
    }, false);
  };
  updateUserData = async () => {
    // TODO: Cookie
    const userData = sessionManager.getDataFromCookies(genericConstants.COOKIES_KEY.USER);

    if (!userData || !Object.keys(userData).length || !userData.userID) {
      return false;
    }

    const { subscription } = userData;
    let userDetails = await firebase._getUserFromUserID(userData.userID);
    const subscriptionData = await firebase.getGlobalSubscription(userData.userID);
    if (!subscriptionData || (subscriptionData.planName === subscription.planName && subscriptionData.expiryDate === subscription.expiryDate && subscriptionData.isSubscribed === subscription.isSubscribed)) {
      return;
    }

    userDetails.subscription = subscriptionData;
    userDetails = new UserModel(userDetails);
    await this.props.updateUserSuccess(userDetails);
  };

  locationChangeListner(){
    history.listen((location, action) => {
      this.updateUserData();
    })

  }

  componentDidMount() {
    if(isWindows) {
      document.body.classList.add('custom-scrollbar');
    }
    this.locationChangeListner();
    this.updateUserData();
  }

  render() {
    const { lang } = this.props.locale;
    const { location } = this.props;
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Socket>
          <ProtectedRoutes router={location}>
            <Notification>
              <OnboardingTour>
                <Router history={history}>
                  <Suspense fallback={<Loader isPageLoader />}>
                    <Switch>
                      <Route exact path="/home" component={LoginPage} />
                      <Route exact path="/home/login" component={LoginPage} />
                      <Route exact path="/home/register" component={RegisterPage} />
                      <Route exact path="/dashboard" component={InboxPage} />
                      <Route exact path="/dashboard/document" component={InboxPage} />
                      <Route exact path="/dashboard/massive-signature" component={MassiveSignaturePage} />
                      <Route exact path="/dashboard/massive-signature/endorsements" ><MassiveSignaturePage status={documentsStatus.PENDING_TO_ENDORSEMENT.slug} /></Route>
                      <Route exact path='/dashboard/template' component={TemplatesPage}/>
                      <Route exact path="/dashboard/tag"><InboxPage activeMenu={genericConstants.ACTIVE_MENU.TAGS} /></Route>
                      <Route exact path="/dashboard/profile" component={ProfilePage} />
                      <Route exact path="/dashboard/users" component={UsersPage} />
                      <Route exact path="/dashboard/validation" component={ValidationPage} />
                      <Route exact path="/document/preview" component={PreviewFileContainer} />
                      <Route exact path="/document/view" component={ViewFileContainer} />
                      <Route exact path="/dashboard/invoice" component={InvoicePage} />
                      <Route exact path="/validation"><ValidationPage isPublic /></Route>
                      <Route exact path="/validation/:encryptedData"><ValidateDocumentIdPage isPublic /></Route>
                      <Route exact path="/validation/:documentID/:timestamp"><ValidateDocumentIdPage isPublic /></Route>
                      <Route exact path="/validation-url" component={ValidateUrlContainer} />
                      <Route exact path="/document/sat" component={SatSignatoryContainer} />
                      <Route exact path="/document/create" component={DocumentCreatorContainer} />
                      <Route exact path="/singing-authority" component={SigningAuthorityContainer} />
                      <Route exact path="/only-me-signing" component={OnlyMeSigningContainer} />
                      <Route exact path="/me-and-other-signing" component={MeAndOtherSigningContainer} />
                      <Route exact path="/other-signing" component={OtherSigningContainer} />
                      <Route exact path="/users/action" component={firebaseEmailActionContainer} />
                      <Route exact path="/users/setup/:userID/:token" component={SubUserAccountSetup} />
                      <Route exact path="/template/signatory/:templateID/:templateHistory" component={TemplateSignatoryContainer} />
                      <Route exact path="/endorsements/validation" component={EndorsementsValidationPage} />
                      <Route exact path='/template/configuration/edit/:templateID'><TemplateConfigPage typeTransaction={templatesConfig.TRANSACTION_TYPE.EDIT} /></Route>
                      <Route exact path='/template/configuration/update/:templateID'><TemplateConfigPage typeTransaction={templatesConfig.TRANSACTION_TYPE.UPDATE} /></Route>
                      <Route exact path='/template/configuration/create'><TemplateConfigPage typeTransaction={templatesConfig.TRANSACTION_TYPE.CREATE} /></Route>
                      <Route exact path='/template/view/single/:templateID/:templateType' component={TemplateSinglePage} />
                      <Route exact path='/template/view/multiple/:templateID/:templateType' component={TemplateMassivePage} />
                      <Route exact path="/document/cancel/:documentId" component={CancelDocumentPage} />
                      <Route exact path="/endorsements/special/:documentId" component={SpecialEndorsementPage} />
                      <Route exact path="/document/cancel-review/:cancelationId" component={CancelDocumentReviewPage} />
                      <Route path="/signatory/form/:documentId/:signatoryId/:timestamp" component={FormSignatoryContainer} />
                      <Route path="/signatory" component={SignatoryContainer} />
                      <Route path="/observer" component={Observer} />
                      <Route path='/vobo/:documentId/:voBoId' component={VoBoPage}/>
                      <Route path='/identity' component={IdentityFlowWithoutDocument}/>
                      <Route path="/facelogin/identity" component={IdentityFlowContainer} />
                      <Route path="/retry/biometric" component={BiometricContainer} />
                      <Route path="/auth/nmp/callback" component={AuthRedirectContainer} />
                      <Route path="/auth/teran/callback" component={TeranAuthPage} />
                      <Route path="/auth/token" component={AuthWithToken} />
                      <Route exact path="/massive-signature/list" component={MassivaSignatureLisContainer} />
                      <Route exact path="/massive-signature/list/endorsements"><MassiveSignatureDetailPage status={documentsStatus.PENDING_TO_ENDORSEMENT.slug} /></Route>
                      <Redirect exact from="*" to="/home" />
                    </Switch>
                  </Suspense>
                </Router>
              </OnboardingTour>
            </Notification>
          </ProtectedRoutes>
        </Socket>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  locale: state.locale,
  user: state.user,
});

const connectedApp = connect(mapStateToProps, {updateUserSuccess})(App);
export default withRouter(connectedApp);
