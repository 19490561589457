import {actionTypeConstant} from '../factories/constant';

export const showBannerTop = (bannerId) => {
  const action = {
    type: actionTypeConstant.SHOW_BANNER_TOP,
    payload: {
      bannerId,
    },
  };

  return (dispatch) => dispatch(action);
};

export const cleanBannerTop = () => {
  const action = {
    type: actionTypeConstant.CLEAR_BANNER_TOP,
  };

  return (dispatch) => dispatch(action);
};
