import React from 'react';
import { Spotlight } from '@atlaskit/onboarding';
import PropTypes from 'prop-types';

import { onboardingTourTargets } from '../../factories/constant';

const OnboardingTour = ({target, targetProps, onNextTarget}) => {
  return <></>;
  return (
    <>
      {target === onboardingTourTargets.TEMPLATES_FEATURE_NEW && (
        <Spotlight
          target={target}
          key={target}
          dialogWidth={310}
          {...targetProps}
        >
          <div className="onboarding-card onboarding-card--center">
            <div className="onboarding-card__subtitle">Nueva funcionalidad disponible</div>
            <div className="onboarding-card__title onboarding-card__title--light">Ahora puedes crear</div>
            <div className="onboarding-card__title">¡tus propias plantillas!</div>
            <div className="onboarding-card__actions">
              <button className="link-theme" onClick={()=>onNextTarget()}>Entendido</button>
            </div>
          </div>
        </Spotlight>
      )}
      {target === onboardingTourTargets.TEMPLATES_PRELOAD && (
        <Spotlight
          target={target}
          key={target}
          dialogWidth={310}
          {...targetProps}
        >
          <div className="onboarding-card onboarding-card--center">
            <div className="onboarding-card__title">Plantilla disponible</div>
            <div className="onboarding-card__message">
              Puedes tener acceso a una plantilla como base para generar las tuyas
            </div>
            <div className="onboarding-card__actions">
              <button className="link-theme" onClick={()=>onNextTarget()}>Entendido</button>
            </div>
          </div>
        </Spotlight>
      )}
      {target === onboardingTourTargets.VOBO_PREVIEW && (
        <Spotlight
          target={target}
          key={target}
          dialogWidth={310}
          {...targetProps}
          dialogPlacement="bottom right"
        >
          <div className="onboarding-card onboarding-card--center">
            <div className="onboarding-card__subtitle">Nueva funcionalidad disponible</div>
            <div className="onboarding-card__title onboarding-card__title--light">Ahora puedes solicitar</div>
            <div className="onboarding-card__title">¡el visto bueno de tu documento!</div>
            <div className="onboarding-card__actions">
              <button className="link-theme" onClick={()=>onNextTarget()}>Entendido</button>
            </div>
          </div>
        </Spotlight>
      )}
      {target === onboardingTourTargets.WHATSAPP_FEATURE && (
        <Spotlight
          target={target}
          key={target}
          dialogWidth={310}
          {...targetProps}
          dialogPlacement="left middle"
        >
          <div className="onboarding-card onboarding-card--center">
            <div className="onboarding-card__subtitle">Nueva funcionalidad disponible</div>
            <div className="onboarding-card__title onboarding-card__title--light">Ahora puedes solicitar firmas <strong>vía WhatsApp</strong></div>
            <div className="onboarding-card__actions">
              <button className="link-theme" onClick={()=>onNextTarget()}>Entendido</button>
            </div>
          </div>
        </Spotlight>
      )}
    </>
  );
};

OnboardingTour.defaultProps = {
  target: '',
  targetProps: {},
  onNextTarget: () => {},
};

OnboardingTour.propTypes = {
  target: PropTypes.string,
  targetProps: PropTypes.object,
  onNextTarget: PropTypes.func,
};

export default OnboardingTour;
