import store from '../store.js';

export const findStorageOnboardingTarget = (userId, target, itemStorage = 'onboarding') => {
  let existTarget = false;
  const onboarding = JSON.parse(localStorage.getItem(itemStorage));
  if (onboarding && onboarding.tour) {
    const userTour = onboarding.tour.find(tour => tour.userId === userId);
    if (userTour && userTour.targets && userTour.targets.length > 0) {
      existTarget = userTour.targets.includes(target);
    }
  }
  return existTarget;
};

export const setStorageOnboardingTarget = (userId, target, itemStorage = 'onboarding') => {
  if (!target) {
    return false;
  }
  const onboarding = JSON.parse(localStorage.getItem(itemStorage));
  let onboardingNewTour = [{userId, targets: [target]}];
  if (onboarding && onboarding.tour) {
    const userTour = onboarding.tour.find(tour => tour.userId === userId);
    if (!userTour) {
      onboardingNewTour = [...onboarding.tour, {userId, targets: [target]}];
    } else {
      const indexTour = onboarding.tour.findIndex(tour => tour.userId === userId);
      let baseTour = onboarding.tour;
      if (!userTour.targets) {
        baseTour[indexTour] = {
          userId,
          targets: [target]
        };
      } else {
        baseTour[indexTour] = {
          userId,
          targets: userTour.targets.includes(target) ? [...userTour.targets] : [...userTour.targets, target],
        }
      }
      onboardingNewTour = baseTour;
    }
  }
  localStorage.setItem(itemStorage, JSON.stringify({
    tour: onboardingNewTour,
  }));
};

export const addTargetToList = (target, targetList) => {
  let elementTargets = [...targetList];
  if (!targetList.includes(target)) {
    elementTargets.push(target);
  }
  return elementTargets;
};
