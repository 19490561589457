export const sessionManager = {
  setDataInCookies,
  getDataFromCookies,
  removeDataFromCookies,
  setDataInLocalStorage,
  getDataFromLocalStorage,
  removeDataFromLocalStorage
};

function setDataInCookies(data, key) {
  localStorage[key] = JSON.stringify(data);
}

function setDataInLocalStorage(data, key) {
  localStorage[key] = JSON.stringify(data);
}

function getDataFromCookies(key) {
  return getDataFromLocalStorage(key);
}

function getDataFromLocalStorage(key) {
  try {
    return localStorage[key] ? JSON.parse(localStorage[key]) : null;
  } catch (err) {
    console.log('Session manager: ', err);
    return null;
  }
}

function removeDataFromCookies(key) {
  localStorage.removeItem(key);
}

function removeDataFromLocalStorage(key) {
  localStorage.removeItem(key);
}
