import { actionTypeConstant, languageConstant } from '../factories/constant';
import { sessionManager } from "../factories/sessionManager";
import DocumentModel from "../factories/models/document";

const document = sessionManager.getDataFromLocalStorage('document');
const countDocument = {
    draft: { count: 0 },
    pending: { count: 0 },
    completed: { count: 0 },
};

let initialState = document ? {
    document,
    countDocument,
    files: [],
} : {
    document: new DocumentModel(),
    countDocument,
    files: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypeConstant.SET_DOCUMENT:
            return {
                ...state,
                document: action.data,
            };
        case actionTypeConstant.SET_MULTIPLE_DOCUMENTS:
            return {
                ...state,
                files: action.data,
            };
        case actionTypeConstant.SET_COUNT_DOCUMENT:
            return {
                ...state,
                countDocument: action.data,
            };
        case actionTypeConstant.LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                document: null,
                lang:languageConstant.ES,
                isLoggedIn: false,
                loginSuccess: true
            };
        case actionTypeConstant.SET_MASSIVE_SIGNATURE_LIST:
            console.log('== SET_MASSIVE_SIGNATURE_LIST ==')
            return {
                ...state,
                pendingDocs: action.data,
            };
        default:
            return state;
    }
}
