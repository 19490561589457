import { actionTypeConstant } from '../factories/constant';

const initialState = {
  endorsements: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypeConstant.SET_ENDORSEMENTS: {
        return {
          ...state,
          endorsements: action.payload,
        }
    }
    default: {
      return state;
    }
  }
}

export default reducer;
