import {
  getTags,
  createTag,
  removeTag,
} from '../services/documentService';

export const setTag = (selectedTag) => ((dispatch) => dispatch({
  type: 'SET_TAG',
  payload: {
    selectedTag,
  },
}));

export const getTagsByUser = (userID) => {
  return (dispatch) => getTags({}, userID)
  .then((tags) => dispatch({
    type: 'GET_TAGS',
    payload: {
      tags,
    },
  }))
  .catch((e) => console.log(e));
};

export const createTagByName = (payload) => {
  return (dispatch) => createTag(payload)
    .then((tag) => dispatch({
      type: 'ADD_TAG',
      payload: {
        tag,
      },
    }))
    .catch((e) => console.log(e));
};


export const removeTagByName = (tag, userID) => {
  const payload = {
    name: tag,
    userID,
  };

  return (dispatch) => removeTag(payload)
    .then((tag) => dispatch({
      type: 'REMOVE_TAG',
      payload: {
        tag,
      },
    }))
    .catch((e) => console.log(e));
};
