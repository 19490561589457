import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { paymentSteps, stripeTransactionType } from '../../../factories/constant';

const PaymentProcess = ({step, title, subtitle, message, help, typeIntent}) => {
  const isSetupIntent = typeIntent === stripeTransactionType.CONFIRM_SETUP || typeIntent === stripeTransactionType.CONFIRM_SETUP_PROMO;
  return (
    <div className="payment-process">
      <div className="payment-process__graphic">
        {step === paymentSteps.PAYMENT_PROCESSING_WITH_DOCUMENT && (
          <img src="/images/procesado.gif" alt="Procesando" />
        )}
        {step === paymentSteps.PAYMENT_PROCESSING && (
          <img src="/images/payment-loader.gif" alt="Procesando" />
        )}
        {step === paymentSteps.PAYMENT_ERROR && (
          <img src="/images/payment-error.svg" alt="Error al procesar" />
        )}
        {step === paymentSteps.PAYMENT_SUCCESS && (
          <>
            {isSetupIntent ? (
              <img src="/images/card-success.svg" alt="Proceso exitoso" />
            ) : (
              <img src="/images/payment-success.svg" alt="Proceso exitoso" />
            )}
          </>
        )}
      </div>
      {title && (
        <div className="wt-dialog__title wt-dialog--start"><FormattedMessage id={title} /></div>
      )}
      {subtitle && !help &&(
        <div className="wt-dialog__subtitle"><FormattedMessage id={subtitle} /></div>
      )}
      {help && (
        <div className="wt-dialog__subtitle">
          <a className="link-theme" onClick={()=>window.drift.api.openChat()}><FormattedMessage id="payment.process.error.persist" /></a>
        </div>
      )}
      <div className="wt-dialog__line-green">
        <img src="images/dialog-line.svg" alt="Variables de template Line" />
      </div>
      {message && (
        <div className="wt-dialog__message"><FormattedMessage id={message} /></div>
      )}
    </div>
  );
};

PaymentProcess.defaultProps = {
  step: paymentSteps.PAYMENT_PROCESSING,
  title: '',
  subtitle: '',
  message: '',
  help: false,
  typeIntent: stripeTransactionType.CONFIRM_PAYMENT,
};

PaymentProcess.propTypes = {
  step: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.string,
  help: PropTypes.bool,
  typeIntent: PropTypes.string,
};

export default PaymentProcess;
