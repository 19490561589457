import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import PaymentNotice from './payment-notice.container';
import AddPaymentMethod from '../components/molecules/payments/add-payment-method.component';
import PaymentMethods from '../components/molecules/payments/payment-methods.component';
import { stripeTransactionType, genericConstants, paymentSteps, googleTagsEvents } from '../factories/constant';
import { updateStatusBanner } from '../utils/notifications.utils';
import { cleanBannerTop } from '../actions/banner.action';
import { updateUserSuccess } from '../actions/user';
import firebase from '../factories/firebase';
import User from '../factories/models/userModel';
import { sendGoogleTagEvent } from '../utils/google-tags.utils';

const PaymentMethodsDialogContainer = ({
  user,
  typeNotice,
  onClose,
  cleanBannerTop,
  updateUserSuccess,
}) => {
  const [isCloseable, setIsCloseable] = useState(true);
  const [stepProcess, setStepProcess] = useState(paymentSteps.PAYMENT_INIT);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(stripeTransactionType.ADD_NEW_CARD);

  const onHandleClose = () => {
    onClose();
  };

  const handleRetryPayment = () => {
    setIsCloseable(true);
    setStepProcess(paymentSteps.PAYMENT_INIT);
  };

  const handleSuccess = () => {
    setIsCloseable(false);
    setStepProcess(paymentSteps.PAYMENT_PROCESSING);
    validateSuscription();
  };

  const handleError = () => {
    setIsCloseable(true);
    setStepProcess(paymentSteps.PAYMENT_ERROR);
  };

  const handleCompletePayment = () => {
    onClose();
  };

  const handleSelectMethod = (method) => {
    setPaymentMethodSelected(method);
  };

  const handleProcessElements = () => {
    setIsCloseable(false);
    setStepProcess(paymentSteps.PAYMENT_PROCESSING);
  };

  const validateSuscription = async() => {
    const responseFirebase = await firebase._getUserFromUserID(user.user.userID);
    if (responseFirebase && Object.keys(responseFirebase).length > 1) {
      let userObj = new User(responseFirebase);
      const suscription = await firebase.getGlobalSubscription(userObj.userID);
      if (suscription) {
        userObj.subscription = suscription;
        updateUserSuccess(userObj);
      }
    }
    onHandleClose();
    cleanBannerTop();
    updateStatusBanner(false);
    setStepProcess(paymentSteps.PAYMENT_SUCCESS);
    sendGoogleTagEvent(googleTagsEvents.CREDIT_CARD_ADDED);
  };

  return (
    <div className="payment-dialog">
      {isCloseable && (
        <div className="wt-dialog__close" onClick={onHandleClose}><i className="wt-icon-close"></i></div>
      )}
      <div className={'payment-dialog__container payment-dialog__container--visible'}>
        {typeNotice !== genericConstants.NOTIFICATION_OPERATION.UPDATE_PAYMENT_METHODS &&
        (
          <div className="payment-dialog__container__line"></div>
        )}
        {typeNotice && (
          <div className="payment-dialog__notice">
            <PaymentNotice type={typeNotice} />
            {typeNotice !== genericConstants.NOTIFICATION_OPERATION.UPDATE_PAYMENT_METHODS && user.user.role !== genericConstants.USER_ROLE.USER && (
              <div className="payment-dialog__notice__action">
                <FormattedMessage id="freeplan.payment.notice.subuser" />
              </div>
            )}
          </div>
        )}
        { user.user.role === genericConstants.USER_ROLE.USER && (
          <div className="payment-dialog__form">
            <PaymentMethods
              currentMethod={paymentMethodSelected}
              onChange={handleSelectMethod}
            />
            <div className={`${paymentMethodSelected === stripeTransactionType.ADD_NEW_CARD ? 'd-block': 'd-none'}`}>
              <AddPaymentMethod
                typeIntent={''}
                disabled={paymentMethodSelected !== stripeTransactionType.ADD_NEW_CARD}
                onSuccess={handleSuccess}
                onProcessing={handleProcessElements}
                onError={handleError}
                buttonTextId="payment.card.setup"
              />
            </div>
            <div className="payment-dialog__controls">
              <div className="payment-dialog__controls__wrapper">
                <a className="payment-dialog__reject" onClick={onHandleClose}>
                  <FormattedMessage id="payment.reject" />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {stepProcess === paymentSteps.PAYMENT_PROCESSING && (
        <div className="payment-dialog__process">
        </div>
      )}
      {stepProcess === paymentSteps.PAYMENT_ERROR && (
        <div className="payment-dialog__process">
          <div className="payment-dialog__process__actions">
            <button className="wt-btn wt-btn--theme wt-btn--circle" onClick={()=>handleRetryPayment()}>
              <FormattedMessage id="payment.retry.link" />
            </button>
          </div>
        </div>
      )}
      {stepProcess === paymentSteps.PAYMENT_SUCCESS && (
        <div className="payment-dialog__process">
          <div className="payment-dialog__process__actions">
            <button className="wt-btn wt-btn--theme wt-btn--circle" onClick={()=>handleCompletePayment()}>
              <FormattedMessage id="payment.process.continue.button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

PaymentMethodsDialogContainer.defaultProps = {
  typeNotice: '',
  typeIntent: '',
  onClose: () => {},
};

PaymentMethodsDialogContainer.propTypes = {
  typeNotice: PropTypes.string,
  typeIntent: PropTypes.string,
  onClose: PropTypes.func,
  user:  PropTypes.object,
  cleanBannerTop: PropTypes.func,
  updateUserSuccess:PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  baseMembership: state.products && state.products.baseMembership ? state.products.baseMembership : null,
});

const mapDispatchToProps = {
  cleanBannerTop,
  updateUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsDialogContainer);
