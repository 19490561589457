import {actionTypeConstant, genericConstants} from '../factories/constant';
import {sessionManager} from '../factories/sessionManager';

export const setDocument = (data) => {
    const action = {
        type: actionTypeConstant.SET_DOCUMENT,
        data
    };
    // TODO: Cookie
    sessionManager.setDataInLocalStorage(data, genericConstants.COOKIES_KEY.DOCUMENT);
    return dispatch => {
        dispatch(action);
    };
};

export const setCountDocuments = (data) => ((dispatch) => dispatch({
    type: actionTypeConstant.SET_COUNT_DOCUMENT,
    data,
}));

export const setMultipleDocuments = (data) => {
    const action = {
        type: actionTypeConstant.SET_MULTIPLE_DOCUMENTS,
        data
    };
    return dispatch => {
        dispatch(action);
    };
};

export const setMassiveSignatureList = (data) => ((dispatch) => dispatch({
  data,
  type: actionTypeConstant.SET_MASSIVE_SIGNATURE_LIST,
}));
