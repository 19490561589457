import {actionTypeConstant, notificationDialogType} from '../factories/constant';

export const showNotification = (notificationId, type = notificationDialogType.NOTICE) => {
  const action = {
    type: actionTypeConstant.SHOW_NOTIFICATION,
    payload: {
      notificationId,
      type,
    },
  };

  return (dispatch) => dispatch(action);
};

export const cleanNotification = () => {
  const action = {
    type: actionTypeConstant.CLEAR_NOTIFICATION,
  };

  return (dispatch) => dispatch(action);
};
