import {actionTypeConstant} from '../factories/constant';

export const setOnboardingTarget = (payload) => {
  const action = {
    type: actionTypeConstant.SET_ONBOARDING_TARGET,
    payload,
  };

  return (dispatch) => dispatch(action);
};

export const setOnboardingList = (payload) => {
  const action = {
    type: actionTypeConstant.SET_ONBOARDING_LIST,
    payload,
  };

  return (dispatch) => dispatch(action);
};
