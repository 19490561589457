import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import { ignoreEnterKey } from '../../../utils';
import { formsErrorConstants, formsFormatsConstants, errorConstants, languageConstant } from '../../../factories/constant';
import messages from '../../../utils/messages';

const ResetPassword = ({
  titleId,
  subtitleId,
  showLogo,
  errorPassword,
  onReset,
}) => {
  const { register, getValues, handleSubmit, trigger, formState: { errors } } = useForm();
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
  const [tokenRecaptcha, setTokenRecaptcha] = useState(null);
  const [isValidRecaptcha, setIsValidRecaptcha] = useState(true);
  const currentLang = localStorage && localStorage.englishLang ? localStorage.englishLang : languageConstant.ES;
  const recaptchaRef = useRef(null);

  const onSubmit = (dataForm) => {
    if(isValidRecaptcha) {
      onReset(tokenRecaptcha, dataForm.password);
    }
  };

  const handleRecaptchaVerify = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setIsValidRecaptcha(recaptchaValue.length > 0);
    setTokenRecaptcha(recaptchaValue);
  };

  const handleValidatePassword = async() => {
    await trigger('password');
    if(errors.password && errors.password.type === 'pattern') {
      setShowPasswordRequirements(true);
    } else {
      setShowPasswordRequirements(false);
    }
    setIsValidPassword(errors.password);
    handleValidateConfirmPassword();
  };

  const handleValidateConfirmPassword = async() => {
    await trigger('confirmPassword');
    setIsValidConfirmPassword(errors.confirmPassword);
  };

  return (
    <div className="recovery-password recovery-password--update">
      {showLogo && (
        <div className="recovery-password__logo">
          <img alt="Recuperar contraseña logo" src="images/logo_multicolor.svg" />
        </div>
      )}
      <div className="recovery-password__title wt-dialog__title wt-dialog--start">
        <FormattedMessage id={titleId} />
      </div>
      <div className="recovery-password__message wt-dialog__message">
        <FormattedMessage id={subtitleId} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => ignoreEnterKey(e)} className="recovery-password__form">
        <div className="recovery-password__form__group">
          <div className="recovery-password__label">
            <FormattedMessage id="register.password" />
          </div>
          <div className="recovery-password__input">
            <input
              name="password"
              type="password"
              className={`input-text input-text--transparent ${errors.password ? 'input-aria-invalid': isValidPassword ? 'input-aria-valid' : ''}`}
              {...register('password', {
                required: { value: true, message: messages[currentLang][errorConstants.FILL_FIELD_MESSAGE] },
                maxLength: { value: 150, message: `${messages[currentLang][formsErrorConstants.MAX_LENGTH]} 150` },
                pattern: { value: formsFormatsConstants.STRONG_PASSWORD, message: messages[currentLang][formsErrorConstants.PASSWORD_STRENGTH] },
                onBlur: e => {handleValidatePassword()},
                onChange: e => {handleValidatePassword()},
              })}
            />
            {errors.password && <span className="form__field__error">{errors.password.message}</span>}
            {showPasswordRequirements && (
              <div className="signup__form__requirements">
                <div className="signup__form__requirements__item">
                  <span className="signup__form__requirements__icon">
                    <i className={`${/(?=.*\d)/.test(getValues('password')) ? 'wt-icon-check' : 'wt-icon-close'}`}></i>
                  </span>
                  <span>{messages[currentLang]['register.password.strength.number']}</span>
                </div>
                <div className="signup__form__requirements__item">
                  <span className="signup__form__requirements__icon">
                    <i className={`${/(?=.*[A-Z])/.test(getValues('password')) ? 'wt-icon-check' : 'wt-icon-close'}`}></i>
                  </span>
                  <span>{messages[currentLang]['register.password.strength.uppercase']}</span>
                </div>
                <div className="signup__form__requirements__item">
                  <span className="signup__form__requirements__icon">
                    <i className={`${/(?=.*[a-z])/.test(getValues('password')) ? 'wt-icon-check' : 'wt-icon-close'}`}></i>
                  </span>
                  <span>{messages[currentLang]['register.password.strength.lowercase']}</span>
                </div>
                <div className="signup__form__requirements__item">
                  <span className="signup__form__requirements__icon">
                    <i className={`${getValues('password').length > 7 ? 'wt-icon-check' : 'wt-icon-close'}`}></i>
                  </span>
                  <span>{messages[currentLang]['register.password.strength.min']}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="recovery-password__form__group">
          <div className="recovery-password__label">
            <FormattedMessage id="profile.change_password.confirm_new_password" />
          </div>
          <div className="recovery-password__input">
            <input 
              name="confirmPassword"
              type="password"
              className={`input-text input-text--transparent ${errors.confirmPassword || errors.password ? 'input-aria-invalid': isValidConfirmPassword ? 'input-aria-valid' : ''}`}
              {...register('confirmPassword', {
                required: { value: true, message: messages[currentLang][errorConstants.FILL_FIELD_MESSAGE] },
                validate: value => value === getValues("password") || messages[currentLang][errorConstants.PASSWORD_AND_CONFIRM_PASSWORD],
                onBlur: e => {handleValidateConfirmPassword()},
                onChange: e => {handleValidateConfirmPassword()},
              })}
            />
            {errors.confirmPassword && <span className="form__field__error">{errors.confirmPassword.message}</span>}
          </div>
        </div>
        <div className="recovery-password__recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={handleRecaptchaVerify}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
            hl={currentLang}
          />
          {!isValidRecaptcha && <div className="form__field__error"><FormattedMessage id="recaptcha.required" /></div>}
          {errorPassword && <div className="form__field__error"><FormattedMessage id={errorPassword} /></div>}
        </div>
        <div className="recovery-password__actions wt-dialog__actions wt-dialog--end">
          <button
            onClick={handleRecaptchaVerify}
            type="submit"
            className="wt-btn wt-btn--circle wt-btn--theme"
          >
            <FormattedMessage id="login.recover_password.send"/>
          </button>
        </div>
      </form>
    </div>
  );
};

ResetPassword.defaultProps = {
  titleId: 'reset.password.heading',
  subtitleId: 'reset.password.subheading',
  showLogo: true,
  onReset: () => {},
};

ResetPassword.propTypes = {
  titleId: PropTypes.string,
  subtitleId: PropTypes.string,
  showLogo: PropTypes.bool,
  onReset: PropTypes.func,
};

export default ResetPassword;
