import { actionTypeConstant } from '../factories/constant';

const emptyProducts = {};

const initialState = emptyProducts;

export default function(state = initialState, action) {
  switch(action.type) {
    case actionTypeConstant.SET_PRODUCTS: {
      return action.payload
    }
    default: {
      return state;
    }
  }
}
